import asami from "../../assets/combatants/underground/hard/asami.png";
import bowalia from "../../assets/combatants/underground/hard/bowalia.png";
import chiyo from "../../assets/combatants/underground/hard/chiyo.png";
import fumiko from "../../assets/combatants/underground/hard/fumiko.png";
import hakaba from "../../assets/combatants/underground/hard/hakaba.png";
import ichiko from "../../assets/combatants/underground/hard/ichiko.png";
import julie from "../../assets/combatants/underground/hard/julie.png";
import junko from "../../assets/combatants/underground/hard/junko.png";
import kaguya from "../../assets/combatants/underground/hard/kaguya.png";
import karen from "../../assets/combatants/underground/hard/karen.png";
import kasu from "../../assets/combatants/underground/hard/kasu.png";
import kuriko from "../../assets/combatants/underground/hard/kuriko.png";
import matarou from "../../assets/combatants/underground/hard/matarou.png";
import matsuko from "../../assets/combatants/underground/hard/matsuko.png";
import moffi from "../../assets/combatants/underground/hard/moffi.png";
import moolYueguang from "../../assets/combatants/underground/hard/mool-yueguang.png";
import roo from "../../assets/combatants/underground/hard/roo.png";
import steiger from "../../assets/combatants/underground/hard/steiger.png";
import subuka from "../../assets/combatants/underground/hard/subuka.png";
import suerte from "../../assets/combatants/underground/hard/suerte.png";
import yuko from "../../assets/combatants/underground/hard/yuko.png";

export default {
  Asami: {
    name: "Asami",
    description: "A powerful fitness enthusiast",
    img: asami,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Elf",
    bodyShape: "muscular",
    eyeColor: "brown",
    hairColor: "blonde",
    skinColor: "fair",
    height: 4,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    // Pref properties effect 'AI'
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText: "Asami roughly licks xxxx's armpits and neck",
    roughPlayLvl: 12, // how responds to domination either -hates or +likes
    // Prowess is capability
    seductionResistance: 45,
    grapplingProwess: 60,
    tongueProwess: 65,
    touchProwess: 45,
    cockProwess: 35,
    vaginaProwess: 38,
    anusProwess: 45,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 45,
    breastResistance: 40,
    mouthResistance: 50,
    cockResistance: 60,
    vaginaResistance: 35,
    anusResistance: 50,
    orgasmLimit: 3,
  },
  Bowalia: {
    name: "Bowalia",
    description:
      "A ex-mma fighter who used to let winners fuck her, little has changed.",
    img: bowalia,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "powerful",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "tan",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Bowalia rooughly gropes xxxx's breasts, but in a pleasurable manner",
    roughPlayLvl: -5,
    seductionResistance: 45,
    grapplingProwess: 62,
    tongueProwess: 45,
    touchProwess: 65,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 22,
    touchResistance: 35,
    breastResistance: 40,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 55,
    anusResistance: 50,
    orgasmLimit: 3,
  },
  Chiyo: {
    name: "Chiyo",
    description: "A futa model girl, she's fucked a number of famous models.",
    img: chiyo,
    gender: "female",
    isWoman: true,
    hasCock: true,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 3,
    breastSize: 5,
    vaginaSize: 4,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "breasts",
    uniqueAttackText:
      "Chiyo smothers xxxx's breasts with her hard cock, teasing her nipples with her large dripping member.",
    roughPlayLvl: 25,
    seductionResistance: 55,
    grapplingProwess: 40,
    tongueProwess: 35,
    touchProwess: 25,
    cockProwess: 65,
    vaginaProwess: 45,
    anusProwess: 30,
    touchResistance: 25,
    breastResistance: 30,
    mouthResistance: 45,
    cockResistance: 50,
    vaginaResistance: 35,
    anusResistance: 44,
    orgasmLimit: 3,
  },
  Fumiko: {
    name: "Fumiko",
    description:
      "A sweet ex-teacher fired for punishing students with a rough deep throat.",
    img: fumiko,
    gender: "female",
    isWoman: true,
    hasCock: true,
    race: "Human",
    bodyShape: "voluptuous",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "tan",
    height: 3,
    penisSize: 4,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "mouth",
    uniqueAttackText:
      "Fumiko drives her sweaty, hard pole deep into xxxx's throat roughly pounding her throat.",
    roughPlayLvl: 32,
    seductionResistance: 35,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 65,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 40,
    breastResistance: 40,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  Hakaba: {
    name: "Hakaba",
    description: "A small but capable dominatrix",
    img: hakaba,
    gender: "female",
    isWoman: true,
    hasCock: true,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "red",
    hairColor: "grey",
    skinColor: "fair",
    height: 1,
    penisSize: 2,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "vagina",
    uniqueAttackText:
      "Hakaba teasaingly slips her strapon in and out fo xxxx's hungry pussy.",
    roughPlayLvl: 22,
    seductionResistance: 45,
    grapplingProwess: 42,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 50,
    vaginaProwess: 38,
    anusProwess: 65,
    touchResistance: 35,
    breastResistance: 43,
    mouthResistance: 50,
    cockResistance: 60,
    vaginaResistance: 35,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  Ichiko: {
    name: "Ichiko",
    description: "An experienced and commanding bdsm master.",
    img: ichiko,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "soft",
    eyeColor: "black",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText:
      "Ichiko rides xxxx's cock all the while chastising them for being a arse loving slut.",
    roughPlayLvl: 12,
    seductionResistance: 52,
    grapplingProwess: 45,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 35,
    vaginaProwess: 40,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 50,
    mouthResistance: 60,
    cockResistance: 25,
    vaginaResistance: 65,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  Julie: {
    name: "Julie",
    description:
      "A popular futa pornstar known for the thick loads she blows into young teen's mouths.",
    img: julie,
    gender: "female",
    isWoman: true,
    hasCock: true,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "black",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 3,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "touch",
    uniqueAttackText:
      "Julie expertly teases xxxx with her cock, running it over her face and slapping them with it.",
    roughPlayLvl: 10,
    seductionResistance: 45,
    grapplingProwess: 52,
    tongueProwess: 55,
    touchProwess: 25,
    cockProwess: 50,
    vaginaProwess: 35,
    anusProwess: 40,
    touchResistance: 45,
    breastResistance: 50,
    mouthResistance: 30,
    cockResistance: 50,
    vaginaResistance: 65,
    anusResistance: 40,
    orgasmLimit: 3,
  },
  Junko: {
    name: "Junko",
    description: "A powerful amazon warrior.",
    img: junko,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "toned",
    eyeColor: "red",
    hairColor: "blonde",
    skinColor: "dark",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Junko's little fingers explore the inside of xxxx's pussy with eagerness.",
    roughPlayLvl: -20,
    seductionResistance: 45,
    grapplingProwess: 65,
    tongueProwess: 65,
    touchProwess: 35,
    cockProwess: 65,
    vaginaProwess: 45,
    anusProwess: 60,
    touchResistance: 25,
    breastResistance: 50,
    mouthResistance: 30,
    cockResistance: 45,
    vaginaResistance: 25,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  Kaguya: {
    name: "Kaguya",
    description: "A cosplayer with a love of being gangbanged.",
    img: kaguya,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "red",
    hairColor: "pink",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "anus",
    uniqueAttackText:
      "Kaguya drives her fist nervously into xxxx's back passage, rubbing expertly against the inner g spot.",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 55,
    touchProwess: 25,
    cockProwess: 50,
    vaginaProwess: 58,
    anusProwess: 25,
    touchResistance: 65,
    breastResistance: 35,
    mouthResistance: 60,
    cockResistance: 40,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  Karen: {
    name: "Karen",
    description: "A respected bodybuilder.",
    img: karen,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "muscular",
    eyeColor: "red",
    hairColor: "white",
    skinColor: "fair",
    height: 4,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Embracing xxxx in a bear hug, she rubs her body against them erotically.",
    roughPlayLvl: 5,
    seductionResistance: 42,
    grapplingProwess: 70,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 35,
    vaginaProwess: 40,
    anusProwess: 55,
    touchResistance: 55,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 35,
    vaginaResistance: 65,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  Kasu: {
    name: "Kasu",
    description: "Despite her experience she is a capable succubus.",
    img: kasu,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Succubus",
    bodyShape: "petite",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 4,
    anusSize: 4,
    throatSize: 4,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText:
      "kasu's tongue fires electric bolts of sexual energy along xxxx's body as it slides over it.",
    roughPlayLvl: -15,
    seductionResistance: 64,
    grapplingProwess: 34,
    tongueProwess: 52,
    touchProwess: 65,
    cockProwess: 30,
    vaginaProwess: 45,
    anusProwess: 42,
    touchResistance: 22,
    breastResistance: 40,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  Kuriko: {
    name: "Kuriko",
    description: "A cute but dedicated weightlifter.",
    img: kuriko,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "muscular",
    eyeColor: "blue",
    hairColor: "brown",
    skinColor: "brown",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Kuriko hungrily drives her tongue around the inside of xxxx's pussy.",
    roughPlayLvl: 12,
    seductionResistance: 35,
    grapplingProwess: 65,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 55,
    vaginaProwess: 35,
    anusProwess: 50,
    touchResistance: 25,
    breastResistance: 30,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 55,
    anusResistance: 42,
    orgasmLimit: 3,
  },
  Matarou: {
    name: "Matarou",
    description: "A very popular maid cafe waitress.",
    img: matarou,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 4,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Matarou expertly pumps xxxx's cock, whilst also pressing her sizable rack into their back.",
    roughPlayLvl: 10,
    seductionResistance: 42,
    grapplingProwess: 55,
    tongueProwess: 55,
    touchProwess: 45,
    cockProwess: 45,
    vaginaProwess: 30,
    anusProwess: 45,
    touchResistance: 55,
    breastResistance: 35,
    mouthResistance: 50,
    cockResistance: 35,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  Matsuko: {
    name: "Matsuko",
    description: "A commanding and beautiful dom.",
    img: matsuko,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "olive",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Matsuko gently massages xxxx's breasts, licking her neck as she does.",
    roughPlayLvl: 35,
    seductionResistance: 64,
    grapplingProwess: 66,
    tongueProwess: 42,
    touchProwess: 55,
    cockProwess: 40,
    vaginaProwess: 45,
    anusProwess: 32,
    touchResistance: 42,
    breastResistance: 25,
    mouthResistance: 50,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  Moffi: {
    name: "Moffi",
    description:
      "Is on a register for repeatedly pressing herself against young schoolgirls on the train.",
    img: moffi,
    gender: "female",
    isWoman: true,
    hasCock: true,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 4,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "cock",
    prefTarget: "anus",
    uniqueAttackText: "Moffi roughly pounds xxxx's anus.",
    roughPlayLvl: 10,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 55,
    cockProwess: 45,
    vaginaProwess: 45,
    anusProwess: 30,
    touchResistance: 35,
    breastResistance: 55,
    mouthResistance: 60,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 62,
    orgasmLimit: 3,
  },
  moolYueguang: {
    name: "Mool Yueguang",
    description: "An aerobics student with a reputation for seducing teachers.",
    img: moolYueguang,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 4,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText:
      "Yueguang jumps on xxxx and starts pressing her toned stomach to their face, intoxicating them with her delicious smell.",
    roughPlayLvl: 15,
    seductionResistance: 42,
    grapplingProwess: 30,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 65,
    vaginaProwess: 60,
    anusProwess: 65,
    touchResistance: 55,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 35,
    vaginaResistance: 65,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  Roo: {
    name: "Roo",
    description: "A busty half lion barmaid.",
    img: roo,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Half-Lion",
    bodyShape: "muscular",
    eyeColor: "brown",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 4,
    anusSize: 4,
    throatSize: 4,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Roo pushes her long tongue deep into xxxx's back passage as she smothers her face with xxxx's butt cheeks.",
    roughPlayLvl: -15,
    seductionResistance: 44,
    grapplingProwess: 54,
    tongueProwess: 52,
    touchProwess: 65,
    cockProwess: 40,
    vaginaProwess: 45,
    anusProwess: 42,
    touchResistance: 42,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  Steiger: {
    name: "Steiger",
    description:
      "A seductive young tyrant who made her female classmates her sex slaves.",
    img: steiger,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "grey",
    hairColor: "blonde",
    skinColor: "light",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText: "Stegier forces her tight pussy over xxxx's face.",
    roughPlayLvl: 32,
    seductionResistance: 45,
    grapplingProwess: 35,
    tongueProwess: 55,
    touchProwess: 45,
    cockProwess: 55,
    vaginaProwess: 65,
    anusProwess: 50,
    touchResistance: 25,
    breastResistance: 40,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 45,
    anusResistance: 42,
    orgasmLimit: 3,
  },
  Subuka: {
    name: "Subuka",
    description: "A rough orc vagabond.",
    img: subuka,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "orc",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "dark",
    height: 4,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 4,
    anusSize: 4,
    throatSize: 4,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Subuka rides xxxx's cock with her hungry cock.",
    roughPlayLvl: 15,
    seductionResistance: 52,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 55,
    vaginaProwess: 40,
    anusProwess: 45,
    touchResistance: 55,
    breastResistance: 45,
    mouthResistance: 50,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  Suerte: {
    name: "Suerte",
    description: "A seductive courtesan of high repute.",
    img: suerte,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "red",
    hairColor: "pink",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Suerte rubs her glistening and soft body against xxxx's.",
    roughPlayLvl: 5,
    seductionResistance: 64,
    grapplingProwess: 46,
    tongueProwess: 52,
    touchProwess: 55,
    cockProwess: 40,
    vaginaProwess: 45,
    anusProwess: 32,
    touchResistance: 42,
    breastResistance: 55,
    mouthResistance: 50,
    cockResistance: 45,
    vaginaResistance: 35,
    anusResistance: 34,
    orgasmLimit: 3,
  },
  Yoku: {
    name: "Yoku",
    description: "A well know show girl and dancer.",
    img: yuko,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "grey",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 4,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Yoku rubs her soft rack against xxxx's tits, running round her nipples in little circles.",
    roughPlayLvl: -5,
    seductionResistance: 45,
    grapplingProwess: 45,
    tongueProwess: 45,
    touchProwess: 55,
    cockProwess: 45,
    vaginaProwess: 35,
    anusProwess: 30,
    touchResistance: 45,
    breastResistance: 55,
    mouthResistance: 60,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 52,
    orgasmLimit: 3,
  },
};
