import Akagi from "../../assets/combatants/battleship/kantai/akagi.png";
import AkagiIcon from "../../assets/combatants/battleship/kantai/akagi-icon.png";
import AkagiWin from "../../assets/combatants/battleship/kantai/akagi-win.png";
import AkagiLose from "../../assets/combatants/battleship/kantai/akagi-lose.png";

import Hibiki from "../../assets/combatants/battleship/kantai/hibiki.png";
import HibikiIcon from "../../assets/combatants/battleship/kantai/hibiki-icon.png";
import HibikiWin from "../../assets/combatants/battleship/kantai/hibiki-win.png";
import HibikiLose from "../../assets/combatants/battleship/kantai/hibiki-lose.png";

import Kaga from "../../assets/combatants/battleship/kantai/kaga.png";
import KagaIcon from "../../assets/combatants/battleship/kantai/kaga-icon.png";
import KagaWin from "../../assets/combatants/battleship/kantai/kaga-win.png";
import KagaLose from "../../assets/combatants/battleship/kantai/kaga-lose.png";

import maxSchultz from "../../assets/combatants/battleship/kantai/max-schultz.png";
import maxSchultzIcon from "../../assets/combatants/battleship/kantai/max-schultz-icon.png";
import maxSchultzWin from "../../assets/combatants/battleship/kantai/max-schultz-win.png";
import maxSchultzLose from "../../assets/combatants/battleship/kantai/max-schultz-lose.png";

import Shimakaze from "../../assets/combatants/battleship/kantai/shimakaze.png";
import ShimakazeIcon from "../../assets/combatants/battleship/kantai/shimakaze-icon.png";
import ShimakazeWin from "../../assets/combatants/battleship/kantai/shimakaze-win.png";
import ShimakazeLose from "../../assets/combatants/battleship/kantai/shimakaze-lose.png";

import Zuikaku from "../../assets/combatants/battleship/kantai/zuikaku.png";
import ZuikakuIcon from "../../assets/combatants/battleship/kantai/zuikaku-icon.png";
import ZuikakuWin from "../../assets/combatants/battleship/kantai/zuikaku-win.png";
import ZuikakuLose from "../../assets/combatants/battleship/kantai/zuikaku-lose.png";

export const seriesId = "Kantai Collection";

export default {
  akagi: {
    seriesId,
    id: "akagi",
    name: "Akagi",
    description:
      "Akagi is shown as a compassionate woman with a gentle, easy-going personality, known for gluttony.",
    img: Akagi,
    icon: AkagiIcon,
    opWinImg: AkagiWin,
    opLoseImg: AkagiLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "red",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Akagi rides xxxx's cock with an animal hunger.",
    roughPlayLvl: 10,
    seductionResistance: 45,
    grapplingProwess: 25,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  hibiki: {
    seriesId,
    id: "hibiki",
    name: "Hibiki",
    description:
      "A calm and stoic Russophile who tends to include Russian words in her speech.",
    img: Hibiki,
    icon: HibikiIcon,
    opWinImg: HibikiWin,
    opLoseImg: HibikiLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "blue",
    hairColor: "light grey",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Hibiki uses her tongue powerfully on xxxx's quivering clit.",
    roughPlayLvl: -10,
    seductionResistance: 35,
    grapplingProwess: 25,
    tongueProwess: 55,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  kaga: {
    seriesId,
    id: "kaga",
    name: "Kaga",
    description:
      "Kaga is a very serious woman with a no-nonsense attitude. She speaks with very little emotion and enthusiasm most of the time, and she keeps words to herself more often than not.",
    img: Kaga,
    icon: KagaIcon,
    opWinImg: KagaWin,
    opLoseImg: KagaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText: "Kaga presses her breasts over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 42,
    grapplingProwess: 38,
    tongueProwess: 25,
    touchProwess: 40,
    cockProwess: 35,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 15,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 35,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  maxSchultz: {
    seriesId,
    id: "maxSchultz",
    name: "Max Schultz",
    description: "Cute submissive german ship commander.",
    img: maxSchultz,
    icon: maxSchultzIcon,
    opWinImg: maxSchultzWin,
    opLoseImg: maxSchultzLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "red",
    hairColor: "brown",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "touch",
    uniqueAttackText: "Mac grinds get lithe body over xxxx's chest.",
    roughPlayLvl: 10,
    seductionResistance: 42,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 40,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  shimakaze: {
    seriesId,
    id: "shimakaze",
    name: "Shimakaze",
    description:
      "Shimakaze is hyperactive and excitable, but is also absent-minded. She prides herself as being the fastest in her fleet, and boasts about her speed very frequently.",
    img: Shimakaze,
    icon: ShimakazeIcon,
    opWinImg: ShimakazeWin,
    opLoseImg: ShimakazeLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "dark grey",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "shimakaze's lither tongue hungry roots around xxxx's pussy.",
    roughPlayLvl: 12,
    seductionResistance: 35,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 30,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance: 35,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 32,
    orgasmLimit: 3,
  },
  zuikaku: {
    seriesId,
    id: "zuikaku",
    name: "Zuikaku",
    description:
      "Zuikaku has a brash, prideful personality, and can be overly confident at times. However, some of her lines imply that she has a more caring side, at least towards her sister, Shoukaku.",
    img: Zuikaku,
    icon: ZuikakuIcon,
    opWinImg: ZuikakuWin,
    opLoseImg: ZuikakuLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "green",
    hairColor: "dark brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText: "Zuikaku gropes xxxx's tits expertly.",
    roughPlayLvl: 10,
    seductionResistance: 35,
    grapplingProwess: 45,
    tongueProwess: 25,
    touchProwess: 40,
    cockProwess: 15,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 31,
    anusResistance: 22,
    orgasmLimit: 3,
  },
};
