import Atago from "../../assets/combatants/battleship/azurLane/atago.png";
import AtagoIcon from "../../assets/combatants/battleship/azurLane/atago-icon.png";
import AtagoWin from "../../assets/combatants/battleship/azurLane/atago-win.png";
import AtagoLose from "../../assets/combatants/battleship/azurLane/atago-lose.png";

import Belfast from "../../assets/combatants/battleship/azurLane/belfast.png";
import BelfastIcon from "../../assets/combatants/battleship/azurLane/belfast-icon.png";
import BelfastWin from "../../assets/combatants/battleship/azurLane/belfast-win.png";
import BelfastLose from "../../assets/combatants/battleship/azurLane/belfast-lose.png";

import LeMalin from "../../assets/combatants/battleship/azurLane/le-malin.png";
import LeMalinIcon from "../../assets/combatants/battleship/azurLane/le-malin-icon.png";
import LeMalinWin from "../../assets/combatants/battleship/azurLane/le-malin-win.png";
import LeMalinLose from "../../assets/combatants/battleship/azurLane/le-malin-lose.png";

import PrinzEugen from "../../assets/combatants/battleship/azurLane/prinz-eugen.png";
import PrinzEugenIcon from "../../assets/combatants/battleship/azurLane/prinz-eugen-icon.png";
import PrinzEugenWin from "../../assets/combatants/battleship/azurLane/prinz-eugen-win.png";
import PrinzEugenLose from "../../assets/combatants/battleship/azurLane/prinz-eugen-lose.png";

export const seriesId = "Azur Lane";

export default {
  atago: {
    seriesId,
    id: "atago",
    name: "Atago",
    description:
      "The second ship of the Takao-class heavy cruiser, flagship of the 2nd division, Atago. Many sisters have seen combat under her lead.",
    img: Atago,
    icon: AtagoIcon,
    opWinImg: AtagoWin,
    opLoseImg: AtagoLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "gold",
    hairColor: "black",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText: "Atago sucks xxxx's cock with an animalistic desire.",
    roughPlayLvl: 10,
    seductionResistance: 40,
    grapplingProwess: 35,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 35,
    breastResistance: 45,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  belfast: {
    seriesId,
    id: "belfast",
    name: "Belfast",
    description:
      "She is the head maid of an Edinburgh Class's second ship: Belfast, the largest cruiser amongst the Royal Navy.",
    img: Belfast,
    icon: BelfastIcon,
    opWinImg: BelfastWin,
    opLoseImg: BelfastLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "blue",
    hairColor: "white",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Belfast gently pleasures xxxx's quivering clit, with her long fingers.",
    roughPlayLvl: -10,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 30,
    cockProwess: 35,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 15,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  leMalin: {
    seriesId,
    id: "leMalin",
    name: "LeMalin",
    description:
      "Le Malin am head shipmen of the Le Fantasque class destroyer, she was part of the 8th Light Division in Brest... and fought together with my sisters.",
    img: LeMalin,
    icon: LeMalinIcon,
    opWinImg: LeMalinWin,
    opLoseImg: LeMalinLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "mouth",
    uniqueAttackText: "LeMalin presses her soft bum over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 32,
    grapplingProwess: 28,
    tongueProwess: 25,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 20,
    anusProwess: 35,
    touchResistance: 25,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 15,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  PrinzEugen: {
    seriesId,
    id: "PrinzEugen",
    name: "Prinz Eugen",
    description:
      "The the miracle of the Ironblood Third Reich, third ship of the Admiral Hipper-class - Prinz Eugen. I can survive whatever kind of combat.",
    img: PrinzEugen,
    icon: PrinzEugenIcon,
    opWinImg: PrinzEugenWin,
    opLoseImg: PrinzEugenLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "red",
    hairColor: "white",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "vagina",
    uniqueAttackText:
      "Prinz Eugen scissors xxxx, pressing her wetness softly against her.",
    roughPlayLvl: 10,
    seductionResistance: 32,
    grapplingProwess: 25,
    tongueProwess: 35,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 35,
    anusResistance: 20,
    orgasmLimit: 3,
  },
};
