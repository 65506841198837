export default [
  {
    displayText: "Play with her body.",
    textOptions: [
      (
        p,
        c
      ) => `${p.name} licked her lips as she eyed the gorgeous girl before her.

    "Well aren't you a pretty thing, you're body is making me very hungry"
    
    She walked up to a restrained ${c.name}, licking her lips. Standing behind her she bent and began groping the girl's supple breasts, and whispered in her ear.
    
    "Well they fell as good as they look, but how do they taste?" Unable to respond because her mouth was bound with a silk cloth, she merely reddened and looked away.
    
    Slowly releasing her fine breasts from her top, ${p.name}  moved to teasing her soft nipples and kissing the reluctant girl's neck. After her hands had sampled the bust, she leaned over, and dripping saliva from her mouth, rained it upon ${c.name}'s chest. Kneeling, she then moved to lustily teasing the girl's nipples with her long tongue, savouring the delightful texture of her ${c.skinColor}, smooth skin. Swallowing her nipples hole she left the girl's breasts red and sticky. She pulled ${c.name}'s head back and again savoured her soft neck, before running her tongue down the girl's body, over her breasts, over her torso, and down to her thighs, each of which she kissed tenderly, then nipped the soft skin with her bared, aroused mouth. Roughly she pushed the girl to the floor.
    
    "Now to taste your sweet pussy."
    
    She placed her head between the gyrating hips, easily prying them apart, and began to savour the taste of her clit, and soft pussy lips. Her tongue explored deeper, tasting the growing wetness of ${c.name}'s cavern, slowly pushing her tongue through. Then she pulled back, and without warning rolled the restrained girl onto her front, with her arms bound she was only able to wriggle uselessly. ${p.name}, then began to spank the girl harshly.
    
    "Your a bad little slut aren't you, moan loader, I want to hear it."
    
    ${c.name} moaned louder as her arse was beaten harshly, pulled of her pants, ${p.name} slid her slit over near ${c.name}'s pussy and began rubbing her clit along her soft thigh. She continued spanking the girl, rubbed herself faster with each muffled moan.
    
    "Thats right you naughty little slut."
    
    ${p.name} dropped the girl's leg, laying her on her back, she crawled over her and placed her wet pussy over the girl's face.
    
    "Are your ready to earn your dinner?"
    
    Meekly ${c.name} nodded, and her gag was removed and ${p.name} covered her face with her pussy. Slowly She began to lick ${p.name}'s dripping pussy, she responed well.
    
    "Ooooh your are talented, mmmmhh"
    
    ${p.name} groaned as her pussy was delicately pleasured, slowly drawing herself into orgasm. A few more minutes of ${c.name}'s soft licks and she was over the edge, shuddering and crying out she coated the sex slaves face in her girlcum. Taking a deep breath she smiled.
    
    "Not bad, maybe I'll keep you around."`,
    ],
  },
  // {
  //   displayText: "Make her pleasure you.",
  //   textOptions: [(p, c) => `${p.name} force ${c.name}to pleasure her`],
  // },
];
