import Daphne from "../../assets/combatants/na-toons/scoobyDoo/daphne.png";
import DaphneIcon from "../../assets/combatants/na-toons/scoobyDoo/daphne-icon.png";
import DaphneWin from "../../assets/combatants/na-toons/scoobyDoo/daphne-win.png";
import DaphneLose from "../../assets/combatants/na-toons/scoobyDoo/daphne-lose.png";

import Velma from "../../assets/combatants/na-toons/scoobyDoo/velma.png";
import VelmaIcon from "../../assets/combatants/na-toons/scoobyDoo/velma-icon.png";
import VelmaWin from "../../assets/combatants/na-toons/scoobyDoo/velma-win.png";
import VelmaLose from "../../assets/combatants/na-toons/scoobyDoo/velma-lose.png";

import April from "../../assets/combatants/na-toons/tmnt/april.png";
import AprilIcon from "../../assets/combatants/na-toons/tmnt/april-icon.png";
import AprilWin from "../../assets/combatants/na-toons/tmnt/april-win.png";
import AprilLose from "../../assets/combatants/na-toons/tmnt/april-lose.png";

import Alex from "../../assets/combatants/na-toons/totallySpies/alex.png";
import AlexIcon from "../../assets/combatants/na-toons/totallySpies/alex-icon.png";
import AlexWin from "../../assets/combatants/na-toons/totallySpies/alex-win.png";
import AlexLose from "../../assets/combatants/na-toons/totallySpies/alex-lose.png";

import Clover from "../../assets/combatants/na-toons/totallySpies/clover.png";
import CloverIcon from "../../assets/combatants/na-toons/totallySpies/clover-icon.png";
import CloverWin from "../../assets/combatants/na-toons/totallySpies/clover-win.png";
import CloverLose from "../../assets/combatants/na-toons/totallySpies/clover-lose.png";

import Mandy from "../../assets/combatants/na-toons/totallySpies/mandy.png";
import MandyIcon from "../../assets/combatants/na-toons/totallySpies/mandy-icon.png";
import MandyWin from "../../assets/combatants/na-toons/totallySpies/mandy-win.png";
import MandyLose from "../../assets/combatants/na-toons/totallySpies/mandy-lose.png";

import Sam from "../../assets/combatants/na-toons/totallySpies/sam.png";
import SamIcon from "../../assets/combatants/na-toons/totallySpies/sam-icon.png";
import SamWin from "../../assets/combatants/na-toons/totallySpies/sam-win.png";
import SamLose from "../../assets/combatants/na-toons/totallySpies/sam-lose.png";

export const seriesId = "Morning Cartoons";

export default {
  daphne: {
    seriesId,
    id: "daphne",
    name: "Daphne",
    description:
      "Daphne Blake is the fashion-loving member of Mystery Inc. She has a bad habit of getting into danger.",
    img: Daphne,
    icon: DaphneIcon,
    opWinImg: DaphneWin,
    opLoseImg: DaphneLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "hourglass",
    eyeColor: "black",
    hairColor: "red",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    // Pref properties effect 'AI'
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText: "Daphne slides her soft pussy lips over xxxx's face'.",
    roughPlayLvl: -5,
    seductionResistance: 42,
    grapplingProwess: 35,
    tongueProwess: 35,
    touchProwess: 25,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 45,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 30,
    breastResistance: 40,
    mouthResistance: 32,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  velma: {
    seriesId,
    id: "velma",
    name: "Velma",
    description:
      "Velma Dinkley is the bespectacled resident genius of Mystery Inc., often being the one to decipher the clues and solve the crimes.",
    img: Velma,
    icon: VelmaIcon,
    opWinImg: VelmaWin,
    opLoseImg: VelmaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "human",
    bodyShape: "curvy",
    eyeColor: "black",
    hairColor: "aubrun",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText:
      "Velma roughly gropes xxxx's breasts, kneading them lovingly.",
    roughPlayLvl: 15,
    seductionResistance: 32,
    grapplingProwess: 45,
    tongueProwess: 15,
    touchProwess: 45,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 10,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 30,
    orgasmLimit: 3,
  },
  april: {
    seriesId,
    id: "april",
    name: "April",
    description:
      "April O'Neil is capable reporter and good friend of the TMNT.",
    img: April,
    icon: AprilIcon,
    opWinImg: AprilWin,
    opLoseImg: AprilLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "shapely",
    eyeColor: "black",
    hairColor: "ginger",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText: "April rubs her soft fingers over xxxx's sopping cunt.",
    roughPlayLvl: 5,
    seductionResistance: 28,
    grapplingProwess: 42,
    tongueProwess: 25,
    touchProwess: 35,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 35,
    touchResistance: 35,
    breastResistance: 45,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  alex: {
    seriesId,
    id: "alex",
    name: "Alex",
    description:
      "Alex is a spy who loves to play sports, especially soccer, video games, and is always trying out some new fad diet.",
    img: Alex,
    icon: AlexIcon,
    opWinImg: AlexWin,
    opLoseImg: AlexLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "toned",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "light brown",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Alex's hungry tongue, runs itself over xxxx's throbbing cock.",
    roughPlayLvl: -10,
    seductionResistance: 35,
    grapplingProwess: 40,
    tongueProwess: 40,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 25,
    cockResistance: 15,
    vaginaResistance: 42,
    anusResistance: 38,
    orgasmLimit: 3,
  },
  clover: {
    seriesId,
    id: "clover",
    name: "Clover",
    description:
      "Clover is a spy who acts like the most typical teenage girl—obsessed with fashion, boys, hot girls, and her appearance.",
    img: Clover,
    icon: CloverIcon,
    opWinImg: CloverWin,
    opLoseImg: CloverLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Clover uses her large tongue to plunder deep into xxxx's arse.",
    roughPlayLvl: 15,
    seductionResistance: 52,
    grapplingProwess: 38,
    tongueProwess: 42,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 25,
    anusProwess: 35,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 45,
    vaginaResistance: 25,
    anusResistance: 55,
    orgasmLimit: 3,
  },
  mandy: {
    seriesId,
    id: "mandy",
    name: "Mandy",
    description:
      "Mandy is the popular girl at Beverly Hills High she is stylish, charismatic and pretty but is also bratty &snobbish",
    img: Mandy,
    icon: MandyIcon,
    opWinImg: MandyWin,
    opLoseImg: MandyLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "purple",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Mandy force's xxxx's cock deep into the back of her throat, looking up sensually as she does.",
    roughPlayLvl: 25,
    seductionResistance: 35,
    grapplingProwess: 42,
    tongueProwess: 35,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  sam: {
    seriesId,
    id: "sam",
    name: "Sam",
    description:
      "Sam is a smart, loyal and sweet person who, just like her two best friends, can become highly jealous.",
    img: Sam,
    icon: SamIcon,
    opWinImg: SamWin,
    opLoseImg: SamLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "green",
    hairColor: "red",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText:
      "Sam uses her tongue expertly to dig deep into xxxx's pussy.",
    roughPlayLvl: 5,
    seductionResistance: 32,
    grapplingProwess: 38,
    tongueProwess: 30,
    touchProwess: 20,
    cockProwess: 25,
    vaginaProwess: 35,
    anusProwess: 25,
    touchResistance: 25,
    breastResistance: 22,
    mouthResistance: 34,
    cockResistance: 32,
    vaginaResistance: 35,
    anusResistance: 25,
    orgasmLimit: 3,
  },
};
