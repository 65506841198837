import { PenisSize, BreastSize, holeSize } from "../../constants";

export default [
  {
    displayText: "Fuck her face",
    textOptions: [
      (p, c) =>
        `Hmmm... ${p.name} wondered, what should I do with her today? ${
          p.pn.Cshe
        } smiled.

        "Lets focus on you swallowing my sexy, thick cock"
        
        ${c.name} looked at ${
          p.name
        } a little upset, then nodded nervously. She turned to face the hefty meat pole poking her face and gave it an afraid look.
        
        "You can do this, your a natural born deep-throater, your mouth just screams fuck me!" ${
          p.name
        } offered.
        
        ${
          c.name
        } took a few deep breaths and began to lather the warm, powerful member with her tongue, then placing the glans in her mouth she took another big breath and pushed forward.
        
        Forcing the ${
          PenisSize[p.penisSize]
        } member down so quickly was a horrendous burden on ${
          c.name
        }'s gag reflex, spit sprayed in jutted bursts from her lips, as her whimpering was hidden by a loud gurgling. Little by little she swallowed the ${
          PenisSize[p.penisSize]
        } cock, her eyes wincing as she pushed further. ${p.name} appreciated ${
          c.name
        }'s upset face, sadistically taking great pleasure in her streaming mascara tears.
        
        ${
          p.name
        }'s sexual rage grew, "Swallow it quicker, you cheap rookie whore, choke your tight, fresh young throat on my hard dick, earn my hot salty spunk."
        
        ${
          c.name
        }'s tears grew at the humiliation, and in large part the pulsing meat rod invading her neck.
        
        Eventually her nose touched ${
          p.name
        }'s chest, who smiled and roughly unplugged  ${
          p.pn.her
        } cock with a loud pop, then pushed the girl down and set about assaulting her soft tits. ${
          c.name
        } let out a soft whimper as she coughed up a big ball of spit and pre-cum.
        
        ${
          p.name
        } fucked her chest for a good while, before stopping and pulling her back on her knees "I'd love to spray a fat, white load all over you, just drench you in it, or feed you every drop of my warm, sticky baby batter, ooooooh, such hard choices, either way you'll need to suck it some more."
        
        ${c.name} shut her eyes fearfully as ${
          p.name
        } roughly plugged the girl’s unready mouth, and began fucking her face again, ${
          p.pn.she
        } moaned loadly as ${p.pn.she} slowly reach orgasm.`,
    ],
  },
  {
    displayText: "Bedtime creampie",
    textOptions: [
      (p, c) =>
        `${p.name} crept in and snuggled into bed with ${c.name}, she turned over.
  
        "Please don't be too rough."
        "I'll try, now lets get your pants free."
    
    ${p.name} began to 69 ${c.name}, plunging ${p.pn.her} cock into ${c.name}'s waiting mouth she moaned as it was delightfully tongued. ${p.name} began work on wetting her clit, and working her G spot with ${p.pn.her} tongue, soon her passage was dripping.
    
    ${p.name} lay on ${p.pn.her} back and instructed ${c.name}.
    
        "Now get on my cock you lazy fuckhole, spear yourself on my throbbing cock."
    
    ${c.name} moved quickly, sliding her cunt deep over the excited member. ${p.name} pulls her down, and her ready pussy rolls almost to the base, and ${p.pn.she} begins to pump up with ${p.pn.her} hips, ${c.name} grimaces at the sudden rush of painful pleasure, she moans loadly as she is vaginally pounded by ${p.name}'s bucking hips. 
    
    ${p.name} ploughs away excitedly, before finally pulling toward a climax, clutching ${c.name} and pulling on her shoulders ${p.name} pulls her round the base of ${p.pn.her} cock, and loudly cries out as she pumps ${p.pn.her} thick semen deep into ${c.name}'s womb. ${c.name} cries out as well as ${p.pn.she} reaches a pitching orgasm, ${p.name} grips her as the last of ${p.pn.her} seed empties out.
    
    Unfullfilled, ${p.name} pulls ${p.pn.her} cock free and pops the cum splattered and still hard cock directly into ${c.name}'s protesting mouth, fucking her face tenderly into the cushion, writhing around, ${c.name}'s legs kick as she loses self control under ${p.name}'s breath-play.
    
    "Ready to breath now cum slut?"
    
    ${p.name} pulls ${p.pn.her} cock free rubs it over the girl's panting face.
    
    "Now for my handjob.."
    
    ${c.name} obligingly pumps the messy penis, bringing it's already throbbing state even higher, ${p.name} moans loadly as ${p.pn.she} is again brought to orgasm.`,
    ],
  },
  {
    displayText: "Use her tits",
    textOptions: [
      (p, c) => `${p.name} bit ${
        p.pn.her
      } lip at the tight sports bra wrapped tightly around the girl’s supple and ${
        BreastSize[c.breastSize]
      } rack. ${p.pn.Cshe} ran ${p.pn.her} hand through ${c.name}’s soft ${
        c.hairColor
      } hair, and getting a solid grip, moving ${p.pn.her} head back ${
        p.pn.she
      } admired the soft skin of ${p.pn.her} neck, ${
        p.pn.her
      } other hand fished out ${p.pn.her} ${
        PenisSize[p.penisSize]
      }, growing dick, pulling down ${p.pn.her} own trousers as ${
        p.pn.she
      } did. Gently ${p.pn.she} rubbed ${p.pn.her} growing phallus against ${
        c.name
      }’s delicate neck.

“Did you wear that for me? sports bra’s on girl are my weakness. Oh I'm going to enjoy this!”

${p.pn.Cher} throbbing cock had grown sizeable, and ${
        p.name
      } now ran it along the Lycra sports wear, pressing it against the centre, and slowly following the breast's soft curve.

“God, ${p.pn.her} ${c.bodyShape} body is a delight.”

${
  p.pn.Cher
} hands moved down, groping the tight, elastic fabric, and roughly groping the breast through the fabric. Then ${
        p.pn.she
      } softy slid a finger between ${c.name}’s tightly wrapped tits.

“Sweaty but not slippery enough.”

The hand moved right, and began kneading the girl's tender breast, ${
        p.pn.her
      } fingers visible under the stretched fabric. ${
        c.name
      } writhed uncomfortably under ${p.pn.her} hungry touch. ${
        p.name
      } pulled free and went to the table, opening a bottle of oil and taking a sniff.

“Smells nice.”

${
  p.pn.Cshe
} smiled, returning, and began to pour the oil onto the restrained girl's chest, biting ${
        p.pn.her
      } lip as ${p.pn.she} squirmed under the strange liquid. ${
        p.name
      } made sure it was well smeared over her breasts. ${
        p.pn.Cshe
      } quickly stripped fully naked, then, pulling ${c.name}'s head to ${
        p.pn.her
      } chest, plunged ${p.pn.her} ${
        PenisSize[p.penisSize]
      }, fully grown cock, into the girl’s bra. The oil lubed ${
        p.pn.her
      } entry nicely and ${p.pn.she} was able to pump ${
        p.pn.her
      } way satisfyingly between the two ${
        BreastSize[c.breastSize]
      }, soft breasts. ${p.pn.Cher} hips began to move faster, and ${
        p.pn.she
      } could hear muffled groans form the girl as her bust was pounded eagerly.

“Fuck yeah, this ${c.name} girl has a great rack, ${p.pn.her} ${
        c.skinColor
      } skin is a delight, I could fuck her for hours!”

${p.name} pounded ${c.name}’s tits harder, stopping only to swirl ${
        p.pn.her
      } oily pre-cum dripping dick around her bra, coating her breasts, and groaning as the girl’s nipples brushed ${
        p.pn.her
      } throbbing cock. ${p.pn.Cshe} pushed deeper, ${
        p.pn.her
      } tip breaking out under the sports bra as  ${
        p.pn.she
      } carried on pounding her abused chest. After a while of vigorous fucking, ${
        p.pn.she
      } slowed, holding ${p.pn.her} sweaty, musky cock under ${
        c.name
      }’s nose, and smiled as the girl’s nose flared.

“Smell that ${
        c.name
      }, that’s my sweaty cock meat, it seems to like you, it wants to rub against you more, and after it’s had it's fill, it's gonna glaze you like a donut!” ${
        c.name
      } cowered.

${p.name} pushed ${c.name} onto her back, and pulled up her top, ${
        p.pn.she
      } rubbed ${p.pn.her} own bare chest against the girls’s, slathering ${
        p.pn.self
      }self in oil and teasing ${
        p.pn.her
      } nipples against the quivering young girl’s, while ${
        p.pn.her
      } hard cock pressed against ${c.name}’s toned abs.

“God your hot, I just want to take you home. Sadly I can't, so I guess I'll have to fuck your beautiful tits till theres not an ounce of cum left in me.”

Sitting on the girl, ${p.name} pressed ${c.name}’s tits tightly over ${
        p.pn.her
      } swollen cock, and continued energetically tit-fucking the girl. Even with the generous helping of oil ${
        p.pn.she
      } still had to slow beacuse of friction, topping up the oil as ${
        p.pn.she
      } pounded the girls chest into the floor.

“I’m gonna cum soon, I'm gonna paint you with my hot warm nut cream till your unrecognizable!”

${c.name} wriggled uncomfortably, ${
        p.pn.she
      } smiled hungrily, and responded. “Complain all you like, losers get spunked on, sorry but I have a huge load brewing, my nuts are sore with this fat creamy load all ready for you!”

    `,
    ],
  },
  {
    displayText: "Pound her arse",
    textOptions: [
      (p, c) => `${p.name} smiled as ${p.pn.she} eyed up ${p.pn.her} prize.

    "Today I think I'm gonna fuck your lovely this arse, such I nice back door needs a good, hard fucking!"
    
    ${c.name} looked at ${
        p.name
      } a little upset, then nodded nervously, giving ${
        p.pn.her
      } an afraid look, she leant forward and presented her behind.
    
    "What a soft peach bum, I can't wait to slip my cock inside" ${
      p.name
    } offered with a deep voice laden with lust.
    
    ${p.name} took a deep breaths and began to slide ${
        p.pn.her
      } powerful member between her soft buttcheeks, hotdogging her arse. ${
        p.pn.Cshe
      } speed increased as ${p.pn.she} enjoyed their soft skin against  ${
        p.pn.her
      } cock.
    
    Then ${p.pn.she} pulled free, and pressing  ${
        p.pn.her
      } glans against her anal ring, forced the ${
        PenisSize[p.penisSize]
      } member down so quickly ,${
        c.name
      } cried out in discomfort, unmercifully ${
        p.pn.she
      } began pounding her abused behind roughly, ignoring her pain. her ${
        holeSize[c.anusSize]
      } arse held ${p.pn.her} cock tightly as ${p.name} continued ${
        p.pn.her
      } anal assualt.
      
      Little by little her arse swallowed the ${
        PenisSize[p.penisSize]
      } cock, her eyes wincing as ${p.pn.she} pushed further in. ${
        p.name
      } then turned a little, allowing ${
        p.pn.her
      } foot to go forward and pin down her head.
    
      "Thats right slut I own you & your arse!" ${
        p.pn.she
      } barked, smushing her mouth with  ${
        p.pn.her
      } foot to stop her complaints, ${
        c.name
      } began tearing up as the arse was brutally destroyed by ${
        p.name
      }'s animal sexual rage. 
    
    "Deeper cum dump, soon you'll be rewarded by my fresh creamy load!"
    
    ${
      p.name
    } continued roughly pounding her arse, before finally pulling free and stepping back, ${
        p.pn.she
      } opened her arse cheeks and enjoyed the view of her loosened arse.
    
    "Good work slut" ${p.pn.Cshe} said patting her tender bottom.`,
    ],
  },
  {
    displayText: "Anal cocksleeve",
    textOptions: [
      (p, c) =>
        `"Time for my fuckslave to pleasure me, I'm getting hungry for you soft arse."
  
        ${p.name} shouted as ${
          p.pn.she
        } entered the room, and walked straight toward ${
          c.name
        }, who scowled aggravatedly. ${p.name} slowly pushed ${
          c.name
        } to the edge of the room and pressed her against the wall, ${
          p.pn.her
        } hands crept under ${
          c.name
        }'s skirt and wrenched down her silky underwear, rubbing ${
          p.pn.her
        } cock with lube, growing it to it's full ${
          PenisSize[p.penisSize]
        } size, ${p.pn.she} guided it in to ${c.name}'s now visible arse. ${
          c.name
        } moaned as the ${PenisSize[p.penisSize]} prick broke through, ${
          p.pn.she
        } pushed on, digging a third of ${p.pn.her} meaty cock into ${
          c.name
        }'s streched behind.
        
            "Shame I missed your tighter years, some of the others girks said you had the greatest arse."
            "I did, till they fucked it loose." ${
              c.name
            } responded in an antoginsed tone.
        
            "Winners perks, win more next time slut, but for now I'm gonna use your arse as my personal cock sleeve."
        
        ${p.name} added as ${p.pn.she} began to hammer at ${
          c.name
        }'s slowly giving anal passage, ${p.pn.Cher} cock
         had now breached halfway in, and ${
           c.name
         }'s expression had turned to one of great discomfort.
        
            "You can't go any further!"
        
        ${c.name} squeaked, ${p.name} ignored her as ${p.pn.she} worked ${
          p.pn.her
        } hips, each thrust sent waves across ${
          c.name
        }'s arse as she was pressed helplessly against the wall. ${
          p.name
        } lent forward and a hand worked its way up ${
          c.name
        }'s top and a hand groped round one of her ${
          BreastSize[p.breastSize]
        } breasts, and began to pull on her nipple, which caused ${
          c.name
        } to moan loadly.
        
        Unable to dig further ${p.name} pulled ${
          p.pn.her
        } cock free, and pulled ${c.name} around.
        
            "Take off your top, I need to taste your slutty breasts."
        
        ${c.name} bit her lip, as ${
          p.name
        } instantly moved as soon as her nipples were free, sucking hungrly on the hard nipples, ${
          p.pn.her
        } other hand toyed with the free nipple before switching sides. ${
          c.name
        } moaned loader.
            
            "Ohhh, your tongue, please, more!"
            "Mmmm.. lovely, but now back to the main course cockslut."
        
        ${p.name} pulled the confused looking ${
          c.name
        } down to the floor, and told her to lay on her front. Spreading ${
          c.name
        }'s arse cheeks again ${p.pn.she} lay onto top of her pushing ${
          p.pn.her
        } fat cock slowly back into place, and raising her arse of the floor. ${
          p.name
        } wrapped ${c.name}'s hair around ${p.pn.her} hands, and pushed ${
          p.pn.her
        } hips foward as ${p.pn.she} pressed ${
          p.pn.her
        } feet into the floor, slowly ${p.pn.she} skewered ${
          c.name
        }'s arse on ${
          p.pn.her
        } hard cock, which had now entered 3/4 of it's length in. ${
          c.name
        } screamed out.
        
            "Arrggh, you can't, you'll break me."
            "I'll do what I have to."
            "I can't feel my legs!
        
        ${p.name} pulled harder, slowly ${p.pn.her} cock slid in further, as ${
          c.name
        } bent backward, finally ${c.name}'s arse slid fully on.
        
            "See I new you had it in you."
        
        ${c.name} could only dribble, half in pain, half in ecstasy. ${
          p.name
        } then pinned ${c.name} to the floor as ${
          p.pn.she
        } lent forward, placing ${
          p.pn.her
        } hands on the girl's shoulders. Rocking on ${p.pn.her} knees ${
          p.name
        } began to fuck ${c.name}'s hole, still resistant ${
          p.pn.she
        } pulled herself forward as ${p.pn.she} slammed into the ${
          c.name
        }'s arse cheeks, each strike producing a loud wail from ${
          c.name
        }. Eventually ${p.name} pressed herself as deep in as ${
          p.pn.she
        } could, and pulled ${p.pn.self}self tight against ${c.name}.
        
            "Oh, ${p.name}!, Fill my arse with your hot seed!" She cried.
            "Enjoy my little cumslut."
        
        ${p.name} bit ${c.name}'s ear as a flood of cum washed out into ${
          c.name
        }'s intestine, three great loads barrelled out, each making ${
          c.name
        } shiver at the feeling. ${p.name} slipped ${
          p.pn.her
        } cock out, and wiped off the fluids on ${
          c.name
        }'s skirt, patted her bum and walked away. Lokoing back, ${
          p.name
        } watched, as ${
          c.name
        } pushed herself slowly to her knees, cum slowly draining from her arse, and wiped a few tears from her eyes.`,
    ],
  },
  {
    displayText: "Destory her pussy",
    textOptions: [
      (p, c) => `${p.name} smiled as ${p.pn.she} eyed up ${p.pn.her} prize.

    "Today I think I'm gonna fuck your lovely this cunt, such a cute girl needs a hard fucking!"
    
    ${c.name} looked at ${
        p.name
      } a little upset, then nodded nervously, giving ${
        p.pn.her
      } an afraid look, she leant backward and presented herself.
    
    "What soft lips, I can't wait to slim my cock inside" ${
      p.name
    } offered with a deep voice laden with lust.
    
    ${p.name} pushed the girl back, lined up ${p.pn.her} and began to slide ${
        p.pn.her
      } powerful member between her the girls soft lower lips. ${
        p.pn.Cshe
      } let ${p.pn.her} speed increase as ${
        p.pn.she
      } enjoyed the soft warmness of ${c.name} sweet wet pussy.
    
    Then ${
      p.pn.she
    } pulled free, and pressing his glans against her clit, and slid ${
        p.pn.her
      } cock between her soft pussy flaps, ${
        c.name
      }'s pussy began to wet in readyness. 
    
    "Now to really have some fun!" ${p.name} smiled as ${p.pn.she} pulled free.
    
    ${p.pn.Cshe} growled and forced the ${
        PenisSize[p.penisSize]
      } member down so quickly into the girl's cunt that ${
        c.name
      } cried out in pain, unmercifully ${
        p.pn.she
      } began pounding her abused pussy roughly, ignoring her complaints. her ${
        holeSize[c.vaginaSize]
      } hole held ${p.pn.her} cock tightly as ${p.name} continued ${
        p.pn.her
      } assualt.
      
      Little by little the girl's pussy swallowed the ${
        PenisSize[p.penisSize]
      } cock, her eyes wincing as ${p.pn.she} pushed further in. ${
        c.name
      } cried out louder in protestation, but ${
        p.name
      } just clamped over her mouth with ${p.pn.her} hand.
    
      "I own you cump dump & your pussy!" ${p.pn.she} barked, ${
        c.name
      } began tearing up as her pussy was brutally destroyed by ${
        p.name
      }'s animal sexual rage. 
    
    "Deeper cum dump, soon you'll be rewarded by my fresh creamy load!"
    
    ${
      p.name
    } continued roughly pounding her cunt, before finally pulling free and stepping back, ${
        p.pn.she
      } opened her legs and enjoyed the view of her loosened pussy.
    
    "Good work slut" ${p.pn.Cshe} said patting one of her legs.`,
    ],
  },
  {
    displayText: "Anal Plunder",
    textOptions: [
      (p, c) =>
        `${
          c.name
        } was quietly sleeping till she felt her pajamas being gently pulled down, slowly she stirred before a sudden pain burned her anal passage, she quickly awoke and began to cry out before her head was roughly pushed into a pillow. A quiet voice whispered into her ear.

        "Quiet now, it's you master, ${
          p.name
        }, I'm here to plunder your curvy butt, hope I am intruding."
        
        ${c.name} could hear ${
          p.name
        } laugh as her anus was pushed open harshly as ${p.name} ${
          PenisSize[p.penisSize]
        } cock dug in. Slowly ${p.name}'s ${
          PenisSize[p.penisSize]
        } member went deeper, a few more merciless pushes and ${
          p.pn.she
        } made ${p.pn.her} way in, pounding roughly into ${c.name}'s insides. ${
          c.name
        } sobbed in pain into the pillow, as ${
          p.pn.she
        } tried to force her face free to breath, ${
          p.name
        } finally got the hint, and grabbing her hair lifted her face free, giving ${
          c.name
        } the chance to take a deep breath.
        
        "Don't say a word my sweet cock puppet!"
        
        ${p.name} pulled ${c.name} onto her knees, going into doggy style so ${
          p.pn.she
        } could pound the girl's arse with more force, ${
          c.name
        } tried to speak but was quickly muffled by ${
          p.name
        }'s hands, who used the facial grip to more roughly pull the struggling girl onto ${
          p.pn.her
        } tightly gripped cock.
        
        Even with all ${p.pn.her} force ${
          p.name
        } could only fuck her tight passage slowly, groaning as the girl slid slopply on and off ${
          p.pn.her
        } cock. ${
          c.name
        } winced and sobbed each time her insides were pounded, grimacing at the slobbery sound of ${
          p.pn.her
        } arse being impaled by the ${
          PenisSize[p.penisSize]
        } beast, her eyes rolled back as ${
          p.name
        } slowly gained speed. Pounding faster ${p.name} begins to caress ${
          c.name
        }'s neck with tongue, nipping and licking the tender skin as ${
          p.pn.her
        } hips slam into the girl's arse with gleeful lust, ${
          p.name
        } moved to nibbling ${c.name}'s ear. 
        
        With a free hand ${p.name} gropes one ${c.name}'s swinging breasts, ${
          p.pn.her
        } other hand still feeling the girl try to cry out, and keeps her mouth clampled.
        
        "God ${
          c.name
        } you have a fine arse, I'm loving pillaging your passage, thank you for being so quiet!"
        
        Finally reaching ${p.pn.her} peak, ${p.name} slams ${
          p.pn.her
        } hips forward, launching ${p.pn.self}self fully in and knocking ${
          c.name
        } into her bed, She quickly holds her face into the pillow with both hands and bites her lip. With a quiet groan ${
          p.pn.her
        } dick releases it's heavy load, a huge wave of hot cum washes into the furthest reaches of ${
          c.name
        }'s insides. ${p.name} pushes ${p.pn.her} hips forward, pressing ${
          c.name
        } into her mattress, as another torrent of cum is pumped into ${
          c.name
        }, a few more pumps squeeze out as ${p.name} slowly pulls ${
          p.pn.her
        } dick free, lifting the girl's hips so she rests on her knees, ensuring the thick, warm serving of semen stays put. ${
          c.name
        }'s head rolls over and her exhausted face breathes quickly as ${
          p.pn.she
        } quickly begins to lose conciousness.
        
        "Oops, maybe I broke this one!, ah well it was worth it, my balls still have a bit more in them I should coat her somewhere before she falls asleep."
       `,
    ],
  },
  {
    displayText: "Furious face fuck",
    textOptions: [
      (p, c) =>
        `${p.name} grabbed ${
          c.name
        } roughly by the hair and Leaned back on the wall below the hot shower, pullling ${
          c.name
        } down by the hair ${
          p.pn.she
        } pushed the shouting girl down to her knees.

      "Noo!, please, I've had enough!"
      "Rules are rule, you're gonna drain my balls cumslut, by taking a merciless facefuck!"
      
      The girl's protesting was cut short as ${p.name} dug ${
          p.pn.her
        } cock into the girl's mouth, pulling on ${p.pn.her} hair ${
          p.pn.she
        } worked ${
          p.pn.her
        } cock deeper into the tight, resisting, gagging throat, tears streamed down ${
          c.name
        }'s face as ${p.name} begun to fuck her cute face.
      
      "Your gonna take my cock deeper cumdump!"
      
      ${p.name} gruffly uttered as ${
          p.pn.she
        } pulled rythmically on the girl's head and flicked with ${
          p.pn.her
        } hips, slowly ${
          p.pn.she
        } managed to fully deepthroat the girl. Pushing ${c.name}'s face to ${
          p.pn.her
        } chest, ${p.pn.she} moaned as ${p.pn.she} held ${
          p.pn.her
        } cock fully in the girls throat, the girl's eyes rolled back as she lot breath. Small coughs squirted saliva as her shuddering throat was stretched by the ${
          PenisSize[p.penisSize]
        } intruder that stretched it and dripped precum into her stomach.
      
      ${
        p.name
      } finally pulled back, smiling as the girl's huge, noisy, slobbering inhale pleasured ${
          p.pn.her
        } hardened cock, which still lay in ${
          c.name
        }'s mouth, giving her but a thin gap through to gather air. Thick strands of spit stretched between ${
          p.pn.her
        } dick and the girl's mouth, more dripped down her chin.
      
      ${
        p.name
      } gave her only a few more breaths before returning to the savage facefucking, ${
          p.name
        }'s strength easily overpowering ${
          c.name
        }'s weak attempts to resist. Spit and precum sprayed out from the girl's abused mouth as the ${
          PenisSize[p.penisSize]
        } member continued to invade her thoat. Black lines of mascara and tears poured down the her face, the deepthroat continued on without a shred of mercy.
      
      Grabbing a fistful of ${c.name}'s hair, ${
          p.name
        } pulled the girl's head back and waited as her eyes slowly looked up, as she did ${
          p.pn.she
        } slowly pulled the cock from her mouth.
      
      "Tell what a cum loving spunk rag you are!"
      
      ${c.name} looked up at ${
          p.pn.her
        } pleadingly, utterly defeated she followed her master's command, her voice wet and distorted by the hard rod resting on her lip.
      
      "I.. I'm a c..cum loving s..s..spunk rag."
      "Yes you are, whose spunk rag are you?"
      "Y..y..you're spunk rag."
      "And what are you only good for?"
      "F..f.. fucking?"
      "And?"
      "Cumming into"
      "Good girl, all you need now is a nice coat of cum!"
      
      And with a devilish smile ${p.name} released a wave of cum onto ${
          c.name
        }'s messy, tired face, ${
          p.pn.her
        } cock pumped repeatedly as thick spurts of semen layered them onto the girl's face and hair. ${
          c.name
        } opened the one eye not covered in cum and looked imploringly up at ${
          p.name
        }. ${p.name} groaned lustilty and with a evil smile, pushed ${
          p.pn.her
        } cock back in, a short cry was quickly drowned out by a spluttering gag, as ${
          c.name
        } was again roughly deepthroated, she beat on ${
          p.name
        } with weary fists who continued on regardless.
      
      "I'm glad theirs fight still in you cumslut, I like you energy!"
      
      She looked down, biting ${p.pn.her} lip as ${
          p.pn.she
        } enjoyed the cum and mascara slathered face repeatedly swallow the huge cock, ${
          p.name
        } continued ${
          p.pn.her
        } second deepthroat with even greater energy, a deepthroat that almost destroyed the slight girl. Thick strands of cum, spit and makeup now hung between her chest and the defeated cumdumpster, wobbling as ${
          p.pn.she
        } fucked her face further.
      
      After a good while of savagery, ${
        p.name
      } again held the girl at full depth, ${
          c.name
        }'s eyes looked up, expressionless in their resigned exhaustion. ${
          p.name
        } slid a leg over ${c.name}'s shoulders, pinning ${
          p.pn.her
        } to the cock, and with a sudden buck of ${p.pn.her} hips, ${
          p.pn.she
        } let forth with a tidal wave of hot, fresh cum. ${
          c.name
        } stiffened, wincing as her attempts to draw breath were pushed back by a monstrous torrent of cum, ${
          p.name
        } bucked ${p.pn.her} hips twice more as ${
          p.pn.she
        } unloaded every last drop ${p.pn.she} could into the defeated girl.
      
      "Shit your a good cum dumpster!"
      
      ${p.name} cried out in heated lust as  ${
          p.pn.she
        } leaned back relaxed against the wall watching as ${
          c.name
        } slowly slid off the cock barely slowing her own fall to her hands, she coughed up two lots of cum, before curling up on the floor. ${
          p.name
        } smiled, and picked up one of ${c.name}'s hands with which ${
          p.pn.she
        } cleaned ${p.pn.her} cock from cum and sweat.
      
      "Thanks for the throat fuck spunk rag!, I wonder if you've left and juice in my balls?"
     `,
    ],
  },
  {
    displayText: "Poolside Pussy",
    textOptions: [
      (p, c) =>
        `${p.name} walked into the pool room hungry with lust, ${
          p.pn.her
        } eyes immediately ate up ${c.name}'s ${c.bodyShape} figure, and ${
          c.hairColor
        } hair, admiring her as she relaxed on the deck chair. Walking over ${
          p.pn.she
        } surprised the napping girl by sitting on her and gently brushed the hair from her face.

        "Hello ${c.name}, are you ready to pleasure me today?"
        "Fuck you!"
        "Either way, I own you, and you now exist to make me cum!"
      
      ${c.name} wriggled and ${p.name} moved onto ${
          p.pn.her
        } knees straddling the girl, ${
          p.pn.she
        } pushed her down as she tried to get up, and with ${
          p.pn.her
        } other hand ${p.pn.she} freed ${p.pn.her} throbbing cock. 
        
        "My cock needs lubricating, now soak it for me."
      
      ${p.name} commanded as ${p.pn.she} pushed the cock onto ${
          c.name
        }'s face, who defeatedly licked the hard rod, her face blushed as she licked more, speeding up ${
          p.pn.she
        } began slather the ${
          PenisSize[p.penisSize]
        } beast in her spit. Well coated ${p.name} bit ${
          p.pn.her
        } lip, and catching ${c.name} by the chin, ${p.pn.she} guided ${
          p.pn.her
        } cock into the girls mouth, ${c.name} gagged as she was penetrated. ${
          p.name
        } then pulled free.
      
        "Thats ready!"
      
      ${p.name} then edged back, and pulled the cord on ${
          c.name
        }'s bikini exposing her ${BreastSize[c.breastSize]}, perky breasts, ${
          p.pn.she
        } then pulled ${c.name}'s knees through ${p.pn.her} legs. Rubbing ${
          c.name
        }'s clit with the tip of ${p.pn.her} rod, ${
          p.pn.she
        } quickly moved to working ${p.pn.her} cock into the gently damp cunt.
      
        "God your nice & tight!"
        "Be gentle, I don't like it when your rough!"
        "Not how the rules work I'm afraid."
      
      ${c.name} began to protest before ${
          p.name
        } clamped a hand over her mouth, and pulled ${
          p.pn.self
        }self into the girl by gripping her shoulder. ${
          c.name
        } struggled as the ${
          PenisSize[p.penisSize]
        } cock stretched her roughly, ${p.name} began to play with ${
          c.name
        }'s nipples, teasing them with tongue and teeth, slowly ${
          p.pn.her
        } hips began to move, sliding stifly in and out of ${
          c.name
        }'s skin tight pussy. Fucking her faster and harder, ${
          c.name
        } gripped ${p.name}'s arms tightly as she was savagedly pounded. 
      
        "Take it you dirty cumslut, god I love fucking the shit out of you cum slaves, I can't wait to get more!"
      
      After a few more rounds of harsh fucking, ${p.name} was finally reach ${
          p.pn.her
        } fill. ${p.pn.Cshe} put ${p.pn.her} hands onto ${
          c.name
        }'s legs, who breathed in deeply and looked exhaustedly up at the roof, ${
          p.name
        } pushed hard, slowly freeing ${p.pn.her} cock from the grip of ${
          c.name
        }'s tight stretched pussy, once free ${
          p.pn.she
        } got up and stood next to her, eyeing her naked form, ${
          p.pn.she
        } laughed as ${p.pn.she} noticed ${
          c.name
        } exhaustedly begin to fall asleep.
      `,
    ],
  },
  {
    displayText: "Futa friends gangbang",
    textOptions: [
      (p, c) =>
        `${p.name} bundles ${c.name} into the car.

        "Where are we going?" She asked nervously.
        "My sparring group is having a meeting, and we need you along."
        "What for?"
        "We need an extra girl."
      
      Finally arriving at the dojo, ${p.name} leads ${c.name} into a back room.
      
        "What do I do?"
        "Bad news cutie, your the entertainment for after the meeting, it was my turn to bring a cum slave."
      
      The girl squeaked in terror.
      
        "What do you mean entertainment?"
        "You know, you lost and became my cum slave, now I have to share you, we destroyed Mary's pet last week, it was a blast, I think she gained a few kilo just from cum!"
        "You.. you.. can't, thats too mean!!" She squeeled fearfully.
        "No rules against it, besides it should be fun, just not for you."
      
      Despite her struggling, and weeping ${
        p.name
      } undressed her to only her cute panties, while ${
          c.name
        } held herself defensively, and looked at ${
          p.name
        } with fear filled eyes. ${
          p.name
        } stroked her hair and pulled her to a wall, ${c.name} whimpered as ${
          p.name
        } lifted ${c.name} and rubbing ${p.pn.her} tip along ${
          c.name
        }'s panties, ${p.pn.she} pulled them to the side and slipped ${
          c.name
        } onto ${p.pn.her} hungry cock, squirming as she slid over it. ${
          p.name
        } held ${c.name} by her hips as ${
          p.pn.she
        } began to pound at the girl's ${holeSize[c.vaginaSize]} cunt. 
      
        "Please!, be gentler."
        "Relax, this is your job, besides, I'm sure I'm not the roughest, or biggest here."
      
      At this ${c.name} wept, ${
          p.name
        } laid her down on a small nearby table and continued working ${
          p.pn.her
        } way slowly deeper into the fresh, ${holeSize[c.vaginaSize]} cunt.
      
      Just as ${
        p.name
      } had worked almost halfway in, the door opened and three beautiful futa's walked in, two curvaceous blondes and a petite brunette.
      
        "Ladies, sorry I've been taking liberties, your welcome to have a go at the other end."
        "Thanks ${
          p.name
        }, ooh, I like this, she looks fresher than the girl the Mary brought, hope her throat is still tight, I hate used throats. 
      
      One of the Blondes took first place at the queue, she hungrily freed her ample cock, and forced her way into the protesting girls mouth, and began stroking her now bulging throat. The brunette got onto the table and straddled the blondes chest, and began rubbing her cock on ${
        c.name
      }'s ${
          BreastSize[c.breastSize]
        } tits. The Blonde began to dig deeper into the girls throat, with a few forceful thrusts she could feel the fuck toy's chin on her chest, and hear the loud heaving and gagging.
      
      ${
        c.name
      } could only see legs, but she could feel her throat be violently assualted, the fat cock fully deepthroating her, the legs now moved ryhtmically in front of her as her face was fucked. Another pair of legs appeared, the cock slowed its assualt, moving backward to her mouth, she managed a few quick breaths before another cock entered, filling her mouth and slapping around. As her already tired throat was again roughly fucked, she felt whoever had been fucked her chest stop and clamber down.
      
        "Your turn now Mary."
        "Thanks!, I'd recommend those tits, they're delightfully soft!"
      
      Another pair of legs moved up and again her throat was assualted, this time by a bigger cock, even after harshly slamming it could not make it all the way. ${
        c.name
      } felt her wet mascara run up her face as both her holes where abused. Mary groaned at the tight throat, then stood back gaving ${
          c.name
        } a chance to breath before trying again.

      "Fuck I love a challenge, this cutie can barely take my fat cock, but she will!"
      
      At the other end, ${
        p.name
      } had managed to dig in fully, despite the squirming of the slowly tiring girl, finally unable to hold back ${
          p.pn.she
        } came. A thick river of cum exploded into ${c.name}'s cunt, slowly ${
          p.name
        } pulled ${p.pn.her} cock free with a loud sticky sound, admiring ${
          p.pn.her
        } messy creampie. Wiping ${
          p.pn.her
        } cock on the girls legs, she let the waiting blonde, Jane, have a go and walked round to the queue at ${
          c.name
        }'s mouth. As jane entered the cum slathered cunt and immediately began pounding away, the door opened and four more attractive Futanari's strolled in and queued up.

      "What a catch, wasting no time I see." One offered.
      "Would you?" Another added, laughing.

      One of the blonde brought herself to a heavy finish rubbing herself against ${
        c.name
      }'s chest, she coated ${
          c.name
        }'s tits in fresh, warm cum, and ran her tip upon the girls nipples before helping one of the new arrivals mount the writhing cum dump, who straight away began to fuck her chest.
      
     Mary held her cock deep in ${c.name}'s ${
          holeSize[c.throatSize]
        } throat as her breath began to wane, cumming a small river into the girl, commanding her as she did.
      
        "Drain my cock, cum whore."
      
      She shared her mouth with a few others who each dumped large, creamy loads into her stomach and mouth, on occasion holding her nose or forcing her to suck them dry as they filled her, taking pleasure in using her as a cumdumpster. Then stood back and admired there work as she coughed up cum and spit, coating her face with a gloopy mask of semen and mascara.
      
      Five more Futa's entered. They deciced to lift ${
        c.name
      } so that one Futa could get under her and sink her thick member into ${
          c.name
        }'s arse, taking turns others began pounding at ${
          c.name
        }'s cum dripping cunt, squelching loudly as cum squeezed out at each thrust. More girls continued to cum onto her tits till semen began to drip down her sides, a few even cheekily cummed on the futa below her, a fine dark skinned, muscled creature, who pounded angrily at the ${
          c.name
        }'s slowly gaping anus. 
      
      One agent with a thick cock now worked at ${
        c.name
      }'s stretched and sagging throat.
      
        "How much did you lot cum, I'm surprised she can breath with the thick loads you've blown in her mouth."

      Eventually deciding to join in a blow her load into ${
        c.name
      }'s stomach, watching, aroused as the girl coughed up more fresh spunk down her face.
      
      ${p.name} returned to ${c.name}'s messy and abused face, shoving ${
          p.pn.her
        } hard dick into ${c.name}'s now relenting throat. Finding a rythm, ${
          p.pn.she
        } mercilessly deepthroated ${p.pn.her} defeated prize. 
      
      The girl beneath ${
        c.name
      } came a magificent load into her arse, just as another finished another big load into her pussy.
      
      ${
        c.name
      }'s body now looked as though a paint can had exploded onto her, and she moved limply, pushed with defeated energy when she needed to breath. ${
          p.name
        } and the other girl eventually came, and watched exitedly as the girl's chest inflated.
      
      The door again opened and a final small group wandered in, the leader, a tall black haired japanese futa, with a hefty E cup, looked lustily at the girl's cum slathered state.
      
        "I see you've all treated yourselves to her ${
          p.name
        }'s slave!, semms we can't cum in her mouth without drowning her, and we're not allowed to."
        "Sorry Ayu!" 
      
      One offered as she piled more cum onto the girls soaked chest. With that Ayu revealed her thick, long member, which drew a few eyes. She walked over to the girls behind and without ceremony forcefully inserted a third of her fat cock into the ${
        c.name
      }'s resisting cunt, which drew a tired kick from her legs. Ayu signalled to the girls and they lifted ${
          c.name
        } up as Ayu moved the floor, slowly she speared the girl on her cock, pulling her down by the hips.
      
      ${
        c.name
      } wept as her cunt was violated, and stretched roughly. The girls now walked round in a girl and taking turns, or occasionally sharing, they deepthroated ${
          c.name
        }, yanking her onto their swollen cocks. Each girl then bukkaking her, coating her face and hair.
      
      By the time half the girls where through her face was almost invisible and her hair was dripping with cum. She had also been speared two thirds of the way onto Ayu's dick, and much of the cum in her arse now sprayed over Ayu and the floor.
      
      Once the girls had finished, ${
        c.name
      } looked like she had emerged from a vat of cum. Ayu requested a hand up, pulled to her feet she lowered ${
          c.name
        } and pressed the back of her shoulders into the floor, and used her bodyweight to dig the rest of huge cock into ${
          c.name
        }'s pussy, squashing her insides and pushing her belly out more. ${
          c.name
        } let out a sharp yelp as she was bent by the force.
      
      Ayu looked about her at the erect cocks on display, most where still masturbating, adding to the thick coating over ${
        c.name
      }'s tits and face, she nodded at ${p.name}.
      
        "${
          p.name
        }, I want you to work her arse, I want you to cum in unison with me."
      
      The futas looked at ${p.name} in lustfull eagerness. ${
          p.name
        } went round Ayu's back, and ${c.name} began to kick weakly. ${
          p.name
        } dug in, her arse was crushed tight by Ayu's thick cock, but ${
          p.pn.she
        } slowly pushed through, pulling on Ayu's powerful hips. It took a few moments of awkward manouvering, but was soon ready, whispering in Ayu's ear, ${
          p.pn.she
        } told ${p.pn.her} ${p.pn.she} was ready.
      
      Suddenly the sound of pumping and fluid could be heard as two thick cocks pumped huge rivers of cum deep into ${
        c.name
      }'s already overfilled holes, cum began to spray out, and still Ayu and ${
          p.name
        } squeezed out more.
      
      Finally they broke free and dropped ${
        c.name
      } on the floor, a huge squirt of cum jetted out from both her holes, she lay quietly breathing, and slathered in cum, a few more futa blasted her with fresh loads.
      
        "Thanks for that ${
          p.name
        }, she was one of the best I've had, such a tight cunt, can't wait for next week, Anne better deliver!."

      The futa's began to filter out, ${
        p.name
      } looked at the destroyed sex slave, and admiring the thick glazing of spunk, felt ${
          p.pn.her
        } balls suggesting ${p.pn.she} had a little more to unload.
      `,
    ],
  },
  {
    displayText: "Sharing an arse with futas",
    textOptions: [
      (p, c) =>
        `${p.name} arrived at the sex dungeon, bringing along some Futanari friends, Clara, Ayu & Steph.
        Clara was dressed in a tight yoga outfit. Ayu a tall, black haired, japanese, E cupped woman with a heavy buldge in her pants, was dressed in buisness outfit. And Steph a tall, muscular, tattoed, blonde with a bouncy G cup, was dressing in very short jeans and a revealing rag that could barely be called a tank top. 
        
        ${p.name} looked at Ayu, who eyed the selection of sex slaves around that ${p.name} had brought. Ayu pointed at ${c.name}, and ${p.name} nodded.
        
          "${c.name}, over here!, now!"
        
        The other girls avoided ${c.name}'s eyes. ${c.name} strode nervously over, Steph spoke up.
        
          "That one?, shes not really my type."
          "Not what we're doing today, that arse however looks tight."
        
        ${c.name} walked over and looked worriedly at Ayu, ${p.name} presented her to Ayu.
        
          "This work for you birthday girl?"
          "Yes, she looks lovely, set her up."
        
        ${p.name} pulled off ${c.name}'s pants, who held herself close, blushing as the futas eyed her hungrily. ${p.name} bent her down and Ayu walked up behind her and freed her enormous cock, ${p.name} looked at it, impressed, and Ayu began to rub on the girl's arse. With ${p.name} spreading her cheeks, she pushed her way in, the tip squished through but quickly stopped, Ayu pushed, and ${c.name} yelled out, but she struggled to go further.
        
          "Wow, as tight as you said, ok you futas warm her up then."
        
        ${p.name} offered next to Steph, and put ${c.name}'s head between her knees, pinning her as she struggled. Steph, guided her tip in gently, then took a strong stance and began to force her way down.
        
          "Damn ${c.name}, your tight, Ayu made a good choice, now accept my fat cock you anal whore."
        
        She pushed more getting a good third in after a while before tiring, she swapped places with ${p.name}, as Ayu & Clara watched on amused. ${p.name} threaded her cock and and pushed up to almost a third, then began to hammer the girl's behind. ${c.name} cried out.
        
          "Please no more, I can't such harsh sodomy, you're gonna break me."
          "Maybe, but training is training, and I'm planning to drive my dick so far it it bludgeons your insides, now open up."
        
        ${p.name} began to hammer harder and the more ${c.name} screamed the further ${p.pn.she} pushed, finally she'd managed to get halfway. Ayu smiled.
        
          "Well done ${p.name}, shame about her chattering, Steph, lay her down."
        
        Steph laid the girl on the floor who lay there clutching her behind and weeping through a grimaced expression. Ayu knelt down before her and lifted her by her hair, pushing her cock forcefully in the girl's mouth.
        
          "This will keep her quiet now Clara your turn."
        
        Clara freed her thick rod and knelt down at the girl's arse, with no pause she mercilessly pushed her way in, pressing with her bodyweight and pinning the girl to the floor, a wet squelch sounded as Clara raped the ${c.name}'s arse.
        
          "God what an disgusting sound, damn anal is fun on a nice arse, you are a gem ${c.name}!, I can still hear her complain with your cock in her mouth Ayu, I'm not surprised, few practiced women can take my thickness, I'll feel bad if I break you, but I'm still gonna try!"
        
        A gargling noise sounded from ${c.name}'s mouth as she was pushed deeper on Ayu's thick cock meat, Ayu stared intently at Clara's invading member, digging it's way violently into the girl's tight refusing passage. Steph and ${p.name} helped by pushing down on Clara's behind, pressing her thick cock further into ${c.name}'s gaping abused arse, finally it slid it, Clara grunted orgasmically, then suddenly shouted.
        
          "Ayu!, ${c.name} can't breath."
        
        It was true, she had been speared on Ayu's cock and was struggling for breath, annoyed Ayu sloppily and loudly peeled her well stuck cock out from ${c.name}'s throat. The girl coughed and fainted.
        
          "What a shitty lightweight, she'd better wake up soon."
        
        Ayu said haughtily. Clara smiled at her, and tried a few harsh pounds into the stil tight and unrelenting passage
        
          "Maybe this will wake her?"
        
        Clara came explosively, the cum could be heard rushing through her cock and deep into the girl's intestines, twice more Clara pumped, pushing as she contended with the tight cling of the girl's arse. Slowly Clara freed her cock, pushing on ${c.name}'s unresponding arse as she worked her way free from the tight grip. She looked at Ayu.
        
          "Shit I think I made her prolapse!"
          "No trouble, ${p.name} and Steph, blow your loads, by then hopefully I can enjoy an awake ${c.name}."
        
        Steph rushed over, and quickly plunged her dick into the now gaping arse, by halfway she could feel the wet slosh of Clara's load, she pushed on. She forced her way deeper, with a little help she speared her full length in, and began to fuck the tight cum filled hole. After a moment she too came, blowing more and more hot cum into the almost overflowing anus.
        
        ${p.name} stepped up, cum could now be seen laying in her wide gaping passage, ${p.pn.her} cock entered easily, only feeling tight after halfway in. Pushing on ${p.pn.she} managed to work ${p.pn.her} own way to the end of ${p.pn.her} shaft, and began to mercilessly fuck the fainted girl's arse, cum began to squeeze out, dripping down the girls legs.
        
          "Damn you make a good fuckhole, this is one of the tightest arse's I've fucked all the way!"
        
        ${c.name} stirred, then suddenly she squealed and clawed at the ground.
        
          "Nooo, why are you still violating me?!"
          "because we haven't all blown a creamy serving of spunk inside you yet, now quit whining and take my load."
        
        ${p.name} then came, pulsing an immense quantity of cum into the girl's arse, and it began squirting out of the sides of ${p.name}'s big cock. ${c.name} complained loader, and ${p.name} lent forward and clamped ${p.pn.her} hands over her mouth, cum blasting her arse once more. Feeling the girl defeated ${p.pn.she} freed herself with much pushing and stood up.
        
          "It's all yours Ayu!"
        
        Ayu smiled and kneeled over the ${c.name}'s behind, running her tip round the girls gaping, cum filled behind, as she moaned and pleaded.
        
          "Relax, it'll make it easier, probably."
        
        She began to thread her tip in, squelching loadly as she entered, ${c.name} tried to free herself but was quickly pinned, Ayu pushed her thick cock down, and began to thrust. Each brutal slam pushed her cock another inch in a recieved a wet cry from the girl. around two thirds in the tightness began to slow her anal assault, so Ayu gritted her teeth and began to fuck harder.
        
          "You're tearing me apart."
          "I'll do what I have to!."
        
        ${c.name} screamed as Ayu dug further, cum began pumping out of her behind with each thrust, spraying over Ayu's chest. With one final burst of energy, Ayu dug her enormous cock the full length in, pulling ${c.name} up by her arms, she then began to savagely fuck her. ${c.name} moved flimsly, as her insides were beaten, finally Ayu dropped her. Pushing on the girl's cheeks she slowly freed her, making peverse wet noises as the passage slowly let her cock free. Standing over the girl's cavernously loose anal corridor, she laughed.
        
          "What a sight, I wonder how much I can pump into her."
        
        Ayu knelt, and with a few final stroles came powerfully, a warm, thick streak emptied into the girl's rectum, splashing into the already present river off cum, filling it, Ayu spread the remains of her incredible load across the ${c.name}'s back.
        
          "What a great cum dumpster, thanks ${p.name}!"
        
        And with that the group walked away, leaving a prone, cum filled ${c.name}, laying defeatedly upon the Court floor, ${p.name} hovered over her, balls yet to be completely emptied, wonder where to finish.
      `,
    ],
  },
];
