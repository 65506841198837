//interface for getting data
import Carmen from "../../assets/body-type/muscular.png";
import Meryl from "../../assets/gender/futa.png";

export const hentaiFoundry = {
  carmen: {
    name: "Carmen",
    description:
      "the sexy and ruthless Carmen, a beautiful girl brought to us by Dmitrys",
    img: Carmen,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "muscular",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "olive",
    height: 4,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 4,
    anusSize: 4,
    throatSize: 4,
    // Pref properties effect 'AI'
    prefAttack: "vagina",
    prefTarget: "cock",
    uniqueAttackText: "Carmen mounts your filty cock and rides you roughshod without breaking eye contact.",
    roughPlayLvl: 0, // how responds to domination either -hates or +likes
    // Prowess is capability
    seductionResistance: 65,
    grapplingProwess: 70,
    tongueProwess: 45,
    touchProwess: 65,
    cockProwess: 65,
    vaginaProwess: 80,
    anusProwess: 60,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 45,
    breastResistance: 46,
    mouthResistance: 60,
    cockResistance: 70,
    vaginaResistance: 90,
    anusResistance: 65,
    orgasmLimit: 4
  },
  meryl: {
    name: "Meryl",
    description:
      "the powerful and well hung Meryl, a alluring but scary futa from Dmitrys",
    img: Meryl,
    gender: "futa",
    isWoman: true,
    hasCock: true,
    race: "Human",
    bodyShape: "muscular",
    eyeColor: "blue",
    hairColor: "white",
    skinColor: "fair",
    height: 4,
    penisSize: 4,
    breastSize: 5,
    vaginaSize: 4,
    anusSize: 5,
    throatSize: 5,
    // Pref properties effect 'AI'
    prefAttack: "cock",
    prefTarget: "mouth",
    uniqueAttackText: "Meryl's massive cock buries itself deep in your oral passage, mercilessly she fucks your face.",
    roughPlayLvl: 20, // how responds to domination either -hates or +likes
    // Prowess is capability
    seductionResistance: 55,
    grapplingProwess: 80,
    tongueProwess: 65,
    touchProwess: 55,
    cockProwess: 85,
    vaginaProwess: 70,
    anusProwess: 75,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 65,
    breastResistance: 50,
    mouthResistance: 50,
    cockResistance: 75,
    vaginaResistance: 65,
    anusResistance: 70,
    orgasmLimit: 3
  }
};

export default hentaiFoundry;
