export default {
  seductionWardrobe: {
    title: "Seduction Wardrobe",
    description: "Improves Seduction training at Training Centre",
    cost: 80,
  },
  grapplingmatt: {
    title: "Grappling Map",
    description: "Improves Grappling training at Training Centre",
    cost: 80,
  },
  bookOfTongues: {
    title: "Book Of Tongues",
    description: "Improves Tongue training at Training Centre",
    cost: 30,
  },
  bookOfSensualTouch: {
    title: "Book Of Sensual Touch",
    description: "Improves Touch training at Training Centre",
    cost: 30,
  },
  onaholetrainer: {
    title: "Onahole Trainer",
    description: "Improves Cock training at Training Centre",
    cost: 30,
  },
  symbian: {
    title: "Symbian",
    description: "Improves Pussy training at Training Centre",
    cost: 30,
  },
  buttplug: {
    title: "Butt Plug",
    description: "Improves Pussy training at Training Centre",
    cost: 30,
  },
  skinMassager: {
    title: "High End Skin Massager",
    description: "Improves touch endurance training",
    cost: 25,
  },
  autoGroper: {
    title: "Auto Groper",
    description: "Improves breast endurance training",
    cost: 25,
  },
  mouthPleaser: {
    title: "Mouth Pleaser",
    description:
      "Spikes sensual waves through the mouth, improves mouth endurance training",
    cost: 25,
  },
  digitalPussy: {
    title: "Digital Pussy",
    description: "Improves cock endurance training",
    cost: 25,
  },
  megaDildo: {
    title: "Mega Dildo",
    description: "Improves pussy endurance training",
    cost: 25,
  },
  arsePleaser: {
    title: "Arse Pleaser",
    description: "Improves anal endurance training",
    cost: 25,
  },
  licenceA: {
    title: "Licence A",
    description: "Decreases price of fucking Losers",
    cost: 30,
  },
  licenceB: {
    title: "Licence B",
    reqItems: ["licenceA"],
    description: "Further decreases price of fucking Losers",
    cost: 60,
  },
  licenceC: {
    title: "Licence C",
    reqItems: ["licenceB"],
    description: "Even further decreases price of fucking Losers",
    cost: 90,
  },
  licencePro: {
    title: "Licence Pro",
    reqItems: ["licenceC"],
    description: "Allows losers to be bought as a sex slave",
    cost: 120,
  },
};
