export default [
  {
    displayText: "Pleasure each other's mouth & breasts.",
    textOptions: [
      (
        p,
        c
      ) => `${p.name} licked her lips as she eyed the gorgeous girl before her.

      "Well aren't you a pretty thing, you're body is making me very hungry"
      
      She walked up to ${c.name}, licking her lips. Standing behind her she bent and began groping the girl's supple breasts, and whispered in her ear.
      
      "Well they fell as good as they look, but how do they taste?"
      "Guess you'll have to taste them" ${c.name} teased.
      
      Slowly releasing her fine breasts, ${p.name} moved to teasing ${c.name}'s soft nipples and kissing her neck, the girl moaned loudly. After her hands had sampled the soft bust, she leaned over, and dripping saliva from her mouth, rained it upon ${c.name}'s chest. Kneeling, she then moved to lustily teasing the girl's nipples with her long tongue, savouring the delightful texture of her ${c.skinColor}, smooth skin. Swallowing her nipples hole she left the girl's breasts red and sticky.
      
      She cut ${c.name}'s moans short by pushing her tongue into her mouth, the two girls kissed deeply, their tongue's fighting to sample eachothers gentle textures, She slowly pulled back and sliver of saliva broke and dropped onto ${c.name}'s breast, slowly ${p.name} licked it clean.
      
      ${p.name} then pulled ${c.name}'s head back and again savoured her soft neck, before running her tongue down the girl's body, over her breasts, over her torso, and down to her thighs, each of which she kissed tenderly, then nipped the soft skin with her bared, aroused mouth. Slowly she pushed the girl to the floor.
      
      "Now I get to taste your sweet pussy."
      
      She placed her head between the supple hips, and slid down, her tongue moving to savour the taste of her clit and soft lower lips. Her tongue explored deeper, tasting the growing wetness of ${c.name}'s cavern, slowly pushing her tongue through. ${c.name} moaned louder, as the tongue lovingly jumped between her cavern and clit, with a short while, ${p.name}'s tender kisses and long tongue brought her to orgasm, ${c.name} bucked and arched in pleasure, shuddering at the wave of pleasure.
      
      ${p.name} stood up and admired the quivering wreck her tongue had made.
      
      "Now my turn."
      
      ${c.name} whimpered in arousal, as ${p.name} knelt down over her and placed her wet pussy over the her face.
      
      "i want your tongue in me beautiful"
      
      ${c.name} slowly She began to lick ${p.name}'s dripping pussy, she responed well, ${p.name} groaned and gyrated as her pussy was expertly eaten out. The girl's tender tongue explored her and teased her most sensitive place, her delicate eyes caught hers and passionatly burned with lust.
      
      "Ooooh your so good!!!, mmmmhh"
      
      ${p.name} groaned as her pussy was delightfully pleasure, slowly driving her into orgasm. A few more minutes of ${c.name}'s expert licks and she was over the edge, shuddering and crying out she coated ${c.name}'s face in her girlcum. Taking a deep breath she smiled.
      
      "Your amazing, we have to do this more often."`,
    ],
  },
  // {
  //   displayText: "Taste each others pussies.",
  //   textOptions: [
  //     (p, c) =>
  //       `${p.name} and ${c.name} take turns devouring each other's pussies`,
  //   ],
  // },
];
