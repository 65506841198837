import Asuka from "../../assets/combatants/fighter/tekken/asuka.png";
import AsukaIcon from "../../assets/combatants/fighter/tekken/asuka-icon.png";
import AsukaWin from "../../assets/combatants/fighter/tekken/asuka-win.png";
import AsukaLose from "../../assets/combatants/fighter/tekken/asuka-lose.png";

import Emilie from "../../assets/combatants/fighter/tekken/emilie.png";
import EmilieIcon from "../../assets/combatants/fighter/tekken/emilie-icon.png";
import EmilieWin from "../../assets/combatants/fighter/tekken/emilie-win.png";
import EmilieLose from "../../assets/combatants/fighter/tekken/emilie-lose.png";

import Nina from "../../assets/combatants/fighter/tekken/nina.png";
import NinaIcon from "../../assets/combatants/fighter/tekken/nina-icon.png";
import NinaWin from "../../assets/combatants/fighter/tekken/nina-win.png";
import NinaLose from "../../assets/combatants/fighter/tekken/nina-lose.png";

import Xiaoyu from "../../assets/combatants/fighter/tekken/xiaoyu.png";
import XiaoyuIcon from "../../assets/combatants/fighter/tekken/xiaoyu-icon.png";
import XiaoyuWin from "../../assets/combatants/fighter/tekken/xiaoyu-win.png";
import XiaoyuLose from "../../assets/combatants/fighter/tekken/xiaoyu-lose.png";

export const seriesId = "Tekken";

export default {
  asuka: {
    seriesId,
    id: "asuka",
    name: "Asuka",
    description:
      "Asuka was born with a strong sense of justice, & trained in Kazama Style Traditional Martial from ayoung age.",
    img: Asuka,
    icon: AsukaIcon,
    opWinImg: AsukaWin,
    opLoseImg: AsukaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "brown",
    hairColor: "dark nrown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText: "Asuka pleasures xxxx's hard shaft with her wet tongue.",
    roughPlayLvl: 10,
    seductionResistance: 65,
    grapplingProwess: 75,
    tongueProwess: 70,
    touchProwess: 30,
    cockProwess: 45,
    vaginaProwess: 35,
    anusProwess: 50,
    touchResistance: 35,
    breastResistance: 52,
    mouthResistance: 40,
    cockResistance: 68,
    vaginaResistance: 45,
    anusResistance: 50,
    orgasmLimit: 3,
  },
  emilie: {
    seriesId,
    id: "emilie",
    name: "Emilie",
    description:
      "Emilie is a Monegasque high school student, & daughter of Mr. Rochefort , a wealthy oil magnate.",
    img: Emilie,
    icon: EmilieIcon,
    opWinImg: EmilieWin,
    opLoseImg: EmilieLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Emilie pleasures xxxx's pussy with a gently hand.",
    roughPlayLvl: -10,
    seductionResistance: 72,
    grapplingProwess: 68,
    tongueProwess: 35,
    touchProwess: 68,
    cockProwess: 35,
    vaginaProwess: 50,
    anusProwess: 20,
    touchResistance: 50,
    breastResistance: 35,
    mouthResistance: 40,
    cockResistance: 55,
    vaginaResistance: 70,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  nina: {
    seriesId,
    id: "nina",
    name: "Nina",
    description:
      "Nina is cold and indifferent, owing to being manipulated when she was young in order to create the perfect assassin.",
    img: Nina,
    icon: NinaIcon,
    opWinImg: NinaWin,
    opLoseImg: NinaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "toned",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Nina spears her arse over xxxx's cock, driving it deep inside her.",
    roughPlayLvl: 32,
    seductionResistance: 82,
    grapplingProwess: 85,
    tongueProwess: 25,
    touchProwess: 60,
    cockProwess: 35,
    vaginaProwess: 70,
    anusProwess: 80,
    touchResistance: 35,
    breastResistance: 45,
    mouthResistance: 60,
    cockResistance: 35,
    vaginaResistance: 75,
    anusResistance: 80,
    orgasmLimit: 3,
  },
  xiaoyu: {
    seriesId,
    id: "xiaoyu",
    name: "Xiaoyu",
    description:
      "Xiaoyu is a naive, innocent and cheerful girl whose story is linked to Jin, her friend who has fallen into evil.",
    img: Xiaoyu,
    icon: XiaoyuIcon,
    opWinImg: XiaoyuWin,
    opLoseImg: XiaoyuLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "brown",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "vagina",
    uniqueAttackText:
      "Xiaoyu tribs herself against xxxx's wet clit.",
    roughPlayLvl: 10,
    seductionResistance: 72,
    grapplingProwess: 76,
    tongueProwess: 35,
    touchProwess: 70,
    cockProwess: 25,
    vaginaProwess: 75,
    anusProwess: 20,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 55,
    vaginaResistance: 65,
    anusResistance: 20,
    orgasmLimit: 3,
  },
};
