import Vikala from "../../assets/combatants/jrpg/granblue/vikala.png";
import VikalaIcon from "../../assets/combatants/jrpg/granblue/vikala-icon.png";
import VikalaWin from "../../assets/combatants/jrpg/granblue/vikala-win.png";
import VikalaLose from "../../assets/combatants/jrpg/granblue/vikala-lose.png";

import Silva from "../../assets/combatants/jrpg/granblue/silva.png";
import SilvaIcon from "../../assets/combatants/jrpg/granblue/silva-icon.png";
import SilvaWin from "../../assets/combatants/jrpg/granblue/silva-win.png";
import SilvaLose from "../../assets/combatants/jrpg/granblue/silva-lose.png";

import Djeeta from "../../assets/combatants/jrpg/granblue/djeeta.png";
import DjeetaIcon from "../../assets/combatants/jrpg/granblue/djeeta-icon.png";
import DjeetaWin from "../../assets/combatants/jrpg/granblue/djeeta-win.png";
import DjeetaLose from "../../assets/combatants/jrpg/granblue/djeeta-lose.png";

import Ferry from "../../assets/combatants/jrpg/granblue/ferry.png";
import FerryIcon from "../../assets/combatants/jrpg/granblue/ferry-icon.png";
import FerryWin from "../../assets/combatants/jrpg/granblue/ferry-win.png";
import FerryLose from "../../assets/combatants/jrpg/granblue/ferry-lose.png";

import Cagliostro from "../../assets/combatants/jrpg/granblue/cagliostro.png";
import CagliostroIcon from "../../assets/combatants/jrpg/granblue/cagliostro-icon.png";
import CagliostroWin from "../../assets/combatants/jrpg/granblue/cagliostro-win.png";
import CagliostroLose from "../../assets/combatants/jrpg/granblue/cagliostro-lose.png";

export const seriesId = "Granblue Fantasy";

export default {
  vikala: {
    seriesId,
    id: "vikala",
    name: "Vikala",
    description:
      "One of the Twelve Divine Generals and guardian of the north. Her 'yang' side is determined to bring hope, dreams, and wonderment to the world.",
    img: Vikala,
    icon: VikalaIcon,
    opWinImg: VikalaWin,
    opLoseImg: VikalaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "red",
    hairColor: "white",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 2,
    // Pref properties effect 'AI'
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Vikala gently suckles xxxx's breasts, with seductive sweetness.",
    roughPlayLvl: -5, // how responds to domination either -hates or +likes
    // Prowess is capability
    seductionResistance: 28,
    grapplingProwess: 22,
    tongueProwess: 32,
    touchProwess: 25,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 15,
    // -- resistance is ability to endure pleasure, 1 - 100
    touchResistance: 30,
    breastResistance: 25,
    mouthResistance: 32,
    cockResistance: 35,
    vaginaResistance: 15,
    anusResistance: 5,
    orgasmLimit: 3,
  },
  silva: {
    seriesId,
    id: "silva",
    name: "Silva",
    description:
      "A istinguished sniper possesses a rare talent, but she never forgets to polish her technique.",
    img: Silva,
    icon: SilvaIcon,
    opWinImg: SilvaWin,
    opLoseImg: SilvaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "human",
    bodyShape: "busty",
    eyeColor: "yellow",
    hairColor: "blue",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 4,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText:
      "Silva eagerly tugs on xxxx's shaft, pumping it with erotic energy.",
    roughPlayLvl: 10,
    seductionResistance: 35,
    grapplingProwess: 35,
    tongueProwess: 15,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 15,
    anusProwess: 10,
    touchResistance: 15,
    breastResistance: 25,
    mouthResistance: 25,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 10,
    orgasmLimit: 3,
  },
  djeeta: {
    seriesId,
    id: "djeeta",
    name: "Djeeta",
    description:
      "Djeeta travels the skies with her flying lizard-like buddy, Vyrn, and the mysterious blue-haired girl who saved her life by linking it with her own, Lyria.",
    img: Djeeta,
    icon: DjeetaIcon,
    opWinImg: DjeetaWin,
    opLoseImg: DjeetaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "youthful",
    eyeColor: "auburn",
    hairColor: "hazel",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText:
      "Djeeta rubs her soft pussy over xxxx's mouth, dripping her juices between them.",
    roughPlayLvl: -10,
    seductionResistance: 25,
    grapplingProwess: 35,
    tongueProwess: 25,
    touchProwess: 30,
    cockProwess: 15,
    vaginaProwess: 45,
    anusProwess: 15,
    touchResistance: 20,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  ferry: {
    seriesId,
    id: "ferry",
    name: "Ferry",
    description:
      "Given a mission from the verdant nature, this young girl sets out on a journey where she crosses paths with an evil spirit.",
    img: Ferry,
    icon: FerryIcon,
    opWinImg: FerryWin,
    opLoseImg: FerryLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Erune",
    bodyShape: "soft",
    eyeColor: "gold",
    hairColor: "blue",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "mouth",
    uniqueAttackText:
      "Ferry's long tongue pushes it's way into xxxx's mouth, exploring hungrily.",
    roughPlayLvl: -10,
    seductionResistance: 40,
    grapplingProwess: 15,
    tongueProwess: 35,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 15,
    cockResistance: 15,
    vaginaResistance: 5,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  cagliostro: {
    seriesId,
    id: "cagliostro",
    name: "Cagliostro",
    description:
      "This supreme genius and pioneer of alchemy transcended time by making herself into an immaterial being.",
    img: Cagliostro,
    icon: CagliostroIcon,
    opWinImg: CagliostroWin,
    opLoseImg: CagliostroLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "purple",
    hairColor: "blonde",
    skinColor: "pale",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText:
      "Cagliostro runs her tongue between xxxx's warm arsecheeks, pleasuring their back door.",
    roughPlayLvl: 25,
    seductionResistance: 45,
    grapplingProwess: 35,
    tongueProwess: 45,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 25,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 30,
    cockResistance: 45,
    vaginaResistance: 15,
    anusResistance: 15,
    orgasmLimit: 3,
  },
};
