import React from 'react';
import PageController from "./PageController"

function App() {
  return (
    <PageController />
  );
}

export default App;
