import Cynthia from "../../assets/combatants/pokemon/cynthia.png";
import CynthiaIcon from "../../assets/combatants/pokemon/cynthia-icon.png";
import CynthiaWin from "../../assets/combatants/pokemon/cynthia-win.png";
import CynthiaLose from "../../assets/combatants/pokemon/cynthia-lose.png";

import Dawn from "../../assets/combatants/pokemon/dawn.png";
import DawnIcon from "../../assets/combatants/pokemon/dawn-icon.png";
import DawnWin from "../../assets/combatants/pokemon/dawn-win.png";
import DawnLose from "../../assets/combatants/pokemon/dawn-lose.png";

import Gloria from "../../assets/combatants/pokemon/gloria.png";
import GloriaIcon from "../../assets/combatants/pokemon/gloria-icon.png";
import GloriaWin from "../../assets/combatants/pokemon/gloria-win.png";
import GloriaLose from "../../assets/combatants/pokemon/gloria-lose.png";

import Green from "../../assets/combatants/pokemon/green.png";
import GreenIcon from "../../assets/combatants/pokemon/green-icon.png";
import GreenWin from "../../assets/combatants/pokemon/green-win.png";
import GreenLose from "../../assets/combatants/pokemon/green-lose.png";

import Hilda from "../../assets/combatants/pokemon/hilda.png";
import HildaIcon from "../../assets/combatants/pokemon/hilda-icon.png";
import HildaWin from "../../assets/combatants/pokemon/hilda-win.png";
import HildaLose from "../../assets/combatants/pokemon/hilda-lose.png";

import Jessie from "../../assets/combatants/pokemon/jessie.png";
import JessieIcon from "../../assets/combatants/pokemon/jessie-icon.png";
import JessieWin from "../../assets/combatants/pokemon/jessie-win.png";
import JessieLose from "../../assets/combatants/pokemon/jessie-lose.png";

import Lillie from "../../assets/combatants/pokemon/lillie.png";
import LillieIcon from "../../assets/combatants/pokemon/lillie-icon.png";
import LillieWin from "../../assets/combatants/pokemon/lillie-win.png";
import LillieLose from "../../assets/combatants/pokemon/lillie-lose.png";

import Marnie from "../../assets/combatants/pokemon/marnie.png";
import MarnieIcon from "../../assets/combatants/pokemon/marnie-icon.png";
import MarnieWin from "../../assets/combatants/pokemon/marnie-win.png";
import MarnieLose from "../../assets/combatants/pokemon/marnie-lose.png";

import May from "../../assets/combatants/pokemon/may.png";
import MayIcon from "../../assets/combatants/pokemon/may-icon.png";
import MayWin from "../../assets/combatants/pokemon/may-win.png";
import MayLose from "../../assets/combatants/pokemon/may-lose.png";

import Misty from "../../assets/combatants/pokemon/misty.png";
import MistyIcon from "../../assets/combatants/pokemon/misty-icon.png";
import MistyWin from "../../assets/combatants/pokemon/misty-win.png";
import MistyLose from "../../assets/combatants/pokemon/misty-lose.png";

import Moon from "../../assets/combatants/pokemon/moon.png";
import MoonIcon from "../../assets/combatants/pokemon/moon-icon.png";
import MoonWin from "../../assets/combatants/pokemon/moon-win.png";
import MoonLose from "../../assets/combatants/pokemon/moon-lose.png";

import Nessa from "../../assets/combatants/pokemon/nessa.png";
import NessaIcon from "../../assets/combatants/pokemon/nessa-icon.png";
import NessaWin from "../../assets/combatants/pokemon/nessa-win.png";
import NessaLose from "../../assets/combatants/pokemon/nessa-lose.png";

import NewSerena from "../../assets/combatants/pokemon/new-serena.png";
import NewSerenaIcon from "../../assets/combatants/pokemon/new-serena-icon.png";
import NewSerenaWin from "../../assets/combatants/pokemon/new-serena-win.png";
import NewSerenaLose from "../../assets/combatants/pokemon/new-serena-lose.png";

import Rosa from "../../assets/combatants/pokemon/rosa.png";
import RosaIcon from "../../assets/combatants/pokemon/rosa-icon.png";
import RosaWin from "../../assets/combatants/pokemon/rosa-win.png";
import RosaLose from "../../assets/combatants/pokemon/rosa-lose.png";

import Serena from "../../assets/combatants/pokemon/serena.png";
import SerenaIcon from "../../assets/combatants/pokemon/serena-icon.png";
import SerenaWin from "../../assets/combatants/pokemon/serena-win.png";
import SerenaLose from "../../assets/combatants/pokemon/serena-lose.png";

export const seriesId = "Pokémon";

export default {
  cynthia: {
    seriesId,
    id: "cynthia",
    name: "Cynthia",
    description:
      "Cynthia is the Champion of the Sinnoh region's Pokémon League.",
    img: Cynthia,
    icon: CynthiaIcon,
    opWinImg: CynthiaWin,
    opLoseImg: CynthiaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "buxom",
    eyeColor: "grey",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "mouth",
    uniqueAttackText:
      "Cynthia rubs her massive sweaty breasts over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 60,
    tongueProwess: 45,
    touchProwess: 52,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 25,
    touchResistance: 45,
    breastResistance: 60,
    mouthResistance: 30,
    cockResistance: 55,
    vaginaResistance: 45,
    anusResistance: 18,
    orgasmLimit: 3,
  },
  dawn: {
    seriesId,
    id: "dawn",
    name: "Dawn",
    description:
      "Dawn is a Pokémon Coordinator from Twinleaf Town and a former traveling companion of Ash.",
    img: Dawn,
    icon: DawnIcon,
    opWinImg: DawnWin,
    opLoseImg: DawnLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "tiny",
    eyeColor: "dark blue",
    hairColor: "dark blue",
    skinColor: "pale",
    height: 1,
    penisSize: 0,
    breastSize: 0,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 3,
    prefAttack: "anus",
    prefTarget: "cock",
    uniqueAttackText: "Dawn presses her tight anus over xxxx's hard cock.",
    roughPlayLvl: -25,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 45,
    touchProwess: 35,
    cockProwess: 20,
    vaginaProwess: 30,
    anusProwess: 25,
    touchResistance: 15,
    breastResistance: 30,
    mouthResistance: 54,
    cockResistance: 30,
    vaginaResistance: 45,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  gloria: {
    seriesId,
    id: "gloria",
    name: "Gloria",
    description:
      "Gloria is the female protagonist in Pokémon Sword and Shield.",
    img: Gloria,
    icon: GloriaIcon,
    opWinImg: GloriaWin,
    opLoseImg: GloriaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "brown",
    hairColor: "brown",
    skinColor: "pale",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText:
      "Gloria rubs her soft, petite against xxxx's pressing her tiny breasts into their back.",
    roughPlayLvl: 8,
    seductionResistance: 42,
    grapplingProwess: 45,
    tongueProwess: 35,
    touchProwess: 58,
    cockProwess: 25,
    vaginaProwess: 45,
    anusProwess: 25,
    touchResistance: 58,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 15,
    vaginaResistance: 25,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  jessie: {
    seriesId,
    id: "jessie",
    name: "Jessie",
    description:
      "Jessie is a member of Team Rocket, that follows Ash, trying to steal his Pikachu.",
    img: Jessie,
    icon: JessieIcon,
    opWinImg: JessieWin,
    opLoseImg: JessieLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "purple",
    skinColor: "pale",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText:
      "Jessie runs her soft pussy lips over xxxx's mouth, letting their pussy juice drip into their mouth.",
    roughPlayLvl: 15,
    seductionResistance: 52,
    grapplingProwess: 62,
    tongueProwess: 25,
    touchProwess: 45,
    cockProwess: 15,
    vaginaProwess: 55,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 60,
    mouthResistance: 32,
    cockResistance: 15,
    vaginaResistance: 55,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  green: {
    seriesId,
    id: "green",
    name: "Green",
    description:
      "As a young child, Green was kidnapped by Ho-Oh and raised by the evil Masked Man, where she met and became close with Silver.",
    img: Green,
    icon: GreenIcon,
    opWinImg: GreenWin,
    opLoseImg: GreenLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "human",
    bodyShape: "youthful",
    eyeColor: "blue",
    hairColor: "aubrun",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "touch",
    uniqueAttackText:
      "Green slides her wet pussy over xxxx's thighs, whilst staring seductively into their eyes.",
    roughPlayLvl: -12,
    seductionResistance: 45,
    grapplingProwess: 50,
    tongueProwess: 55,
    touchProwess: 35,
    cockProwess: 15,
    vaginaProwess: 45,
    anusProwess: 10,
    touchResistance: 45,
    breastResistance: 25,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  hilda: {
    seriesId,
    id: "hilda",
    name: "Hilda",
    description: "Hilda is the female protagonist in Pokémon Black and White.",
    img: Hilda,
    icon: HildaIcon,
    opWinImg: HildaWin,
    opLoseImg: HildaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "tight",
    eyeColor: "blue",
    hairColor: "dark brown",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "anus",
    prefTarget: "mouth",
    uniqueAttackText:
      "Hilda pushes her soft, plushy arsecheecks over xxxx's face.",
    roughPlayLvl: 12,
    seductionResistance: 45,
    grapplingProwess: 40,
    tongueProwess: 30,
    touchProwess: 20,
    cockProwess: 25,
    vaginaProwess: 15,
    anusProwess: 35,
    touchResistance: 35,
    breastResistance: 25,
    mouthResistance: 35,
    cockResistance: 25,
    vaginaResistance: 30,
    anusResistance: 55,
    orgasmLimit: 3,
  },
  lillie: {
    seriesId,
    id: "lillie",
    name: "Lillie",
    description: "Lillie is a mysterious girl who assists Professor Kukui, she prefers reading to Pokémon battles.",
    img: Lillie,
    icon: LillieIcon,
    opWinImg: LillieWin,
    opLoseImg: LillieLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "green",
    hairColor: "blonde",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 0,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "vagina",
    prefTarget: "vagina",
    uniqueAttackText: "Lillie scissors xxxx's pussy with an eager youthful energy.",
    roughPlayLvl: -12,
    seductionResistance: 52,
    grapplingProwess: 45,
    tongueProwess: 32,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 25,
    anusProwess: 35,
    touchResistance: 15,
    breastResistance: 35,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 55,
    anusResistance: 15,
    orgasmLimit: 3,
  },
  marnie: {
    seriesId,
    id: "marnie",
    name: "Marnie",
    description: "Marnie is a stoic, but well-mannered girl who wants to become Champion to support her hometown Spikemuth.",
    img: Marnie,
    icon: MarnieIcon,
    opWinImg: MarnieWin,
    opLoseImg: MarnieLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "green",
    hairColor: "black",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText: "Marnie snuggles behind xxxx and softly fingers her pussy.",
    roughPlayLvl: 25,
    seductionResistance: 52,
    grapplingProwess: 52,
    tongueProwess: 25,
    touchProwess: 52,
    cockProwess: 15,
    vaginaProwess: 25,
    anusProwess: 45,
    touchResistance: 55,
    breastResistance: 45,
    mouthResistance: 60,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  may: {
    seriesId,
    id: "may",
    name: "May",
    description: "May is a Pokémon Coordinator from Petalburg City and a former traveling companion of Ash.",
    img: May,
    icon: MayIcon,
    opWinImg: MayWin,
    opLoseImg: MayLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "blue",
    hairColor: "light brown",
    skinColor: "pale",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText: "May's little tongue lovingly suckles on xxxx's nipples.",
    roughPlayLvl: -10,
    seductionResistance: 48,
    grapplingProwess: 52,
    tongueProwess: 52,
    touchProwess: 20,
    cockProwess: 15,
    vaginaProwess: 20,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance:45,
    mouthResistance: 25,
    cockResistance: 15,
    vaginaResistance: 52,
    anusResistance: 38,
    orgasmLimit: 3,
  },
  misty: {
    seriesId,
    id: "misty",
    name: "Misty",
    description: "Misty is an aspiring Water Pokémon Master & a former traveling partner of Ash. She is also the Gym Leader of the Cerulean Gym.",
    img: Misty,
    icon: MistyIcon,
    opWinImg: MistyWin,
    opLoseImg: MistyLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "viridian",
    hairColor: "orange",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "vagina",
    uniqueAttackText: "Misty uses her small tongue to cover xxxx's clit in sweet little licks.",
    roughPlayLvl: -20,
    seductionResistance: 55,
    grapplingProwess: 60,
    tongueProwess: 55,
    touchProwess: 20,
    cockProwess: 35,
    vaginaProwess: 40,
    anusProwess: 40,
    touchResistance: 35,
    breastResistance: 55,
    mouthResistance: 20,
    cockResistance: 35,
    vaginaResistance: 62,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  moon: {
    seriesId,
    id: "moon",
    name: "Moon",
    description: "Moon is the female protagonist in Pokémon Sun & Moon.",
    img: Moon,
    icon: MoonIcon,
    opWinImg: MoonWin,
    opLoseImg: MoonLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "grey",
    hairColor: "black",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 0,
    vaginaSize: 0,
    anusSize: 0,
    throatSize: 0,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText: "Moon rubs her lithe, petite body against xxxx's pulsing cock.",
    roughPlayLvl: 5,
    seductionResistance: 42,
    grapplingProwess: 48,
    tongueProwess: 25,
    touchProwess: 50,
    cockProwess: 35,
    vaginaProwess: 35,
    anusProwess: 25,
    touchResistance: 45,
    breastResistance: 35,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  Nessa: {
    seriesId,
    id: "Nessa",
    name: "Nessa",
    description: "Nessa is the Gym Leader of Hulbury's Gym, known officially as Hulbury Stadium.",
    img: Nessa,
    icon: NessaIcon,
    opWinImg: NessaWin,
    opLoseImg: NessaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "blue",
    hairColor: "blue",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "anus",
    uniqueAttackText: "Nessa's small hands tease the insides of xxxx's arse.",
    roughPlayLvl: 20,
    seductionResistance: 52,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 45,
    cockProwess: 15,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 55,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 15,
    vaginaResistance: 45,
    anusResistance: 40,
    orgasmLimit: 3,
  },
  newSerena: {
    seriesId,
    id: "newSerena",
    name: "Serena",
    description: "Serena is a Pokémon Performer and a former traveling companion of Ash.",
    img: NewSerena,
    icon: NewSerenaIcon,
    opWinImg: NewSerenaWin,
    opLoseImg: NewSerenaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "small",
    eyeColor: "blue",
    hairColor: "honey",
    skinColor: "fair",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 1,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText: "Serena gently nuzzles xxxx's back passage with her cute tongue.",
    roughPlayLvl: -12,
    seductionResistance: 45,
    grapplingProwess: 45,
    tongueProwess: 45,
    touchProwess: 30,
    cockProwess: 25,
    vaginaProwess: 30,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance: 35,
    mouthResistance: 30,
    cockResistance: 25,
    vaginaResistance: 25,
    anusResistance: 38,
    orgasmLimit: 3,
  },
  rosa: {
    seriesId,
    id: "rosa",
    name: "Rosa",
    description: "Rosa is the female player character in Pokémon Black 2 and White 2.",
    img: Rosa,
    icon: RosaIcon,
    opWinImg: RosaWin,
    opLoseImg: RosaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "tight",
    eyeColor: "blue",
    hairColor: "brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText: "Rosa's elegant fingers attempt to pump fresh cum from xxxx's hard penis.",
    roughPlayLvl: 10,
    seductionResistance: 55,
    grapplingProwess: 45,
    tongueProwess: 25,
    touchProwess: 42,
    cockProwess: 25,
    vaginaProwess: 40,
    anusProwess: 20,
    touchResistance: 25,
    breastResistance: 25,
    mouthResistance: 40,
    cockResistance: 35,
    vaginaResistance: 41,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  serena: {
    seriesId,
    id: "serena",
    name: "Serena",
    description: "Serena is the female protagonist in Pokémon X and Y.",
    img: Serena,
    icon: SerenaIcon,
    opWinImg: SerenaWin,
    opLoseImg: SerenaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "toned",
    eyeColor: "grey",
    hairColor: "honey",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText: "Serena sucks xxxx's glans, letting her tongue slide slowly up and down the shaft.",
    roughPlayLvl: 12,
    seductionResistance: 50,
    grapplingProwess: 55,
    tongueProwess: 55,
    touchProwess: 30,
    cockProwess: 25,
    vaginaProwess: 35,
    anusProwess: 20,
    touchResistance: 45,
    breastResistance: 45,
    mouthResistance: 20,
    cockResistance: 25,
    vaginaResistance: 35,
    anusResistance: 25,
    orgasmLimit: 3,
  },
};
