import Christie from "../../assets/combatants/fighter/doa/christie.png";
import ChristieIcon from "../../assets/combatants/fighter/doa/christie-icon.png";
import ChristieWin from "../../assets/combatants/fighter/doa/christie-win.png";
import ChristieLose from "../../assets/combatants/fighter/doa/christie-lose.png";

import Helena from "../../assets/combatants/fighter/doa/helena.png";
import HelenaIcon from "../../assets/combatants/fighter/doa/helena-icon.png";
import HelenaWin from "../../assets/combatants/fighter/doa/helena-win.png";
import HelenaLose from "../../assets/combatants/fighter/doa/helena-lose.png";

import Hitomi from "../../assets/combatants/fighter/doa/hitomi.png";
import HitomiIcon from "../../assets/combatants/fighter/doa/hitomi-icon.png";
import HitomiWin from "../../assets/combatants/fighter/doa/hitomi-win.png";
import HitomiLose from "../../assets/combatants/fighter/doa/hitomi-lose.png";

import Lisa from "../../assets/combatants/fighter/doa/lisa.png";
import LisaIcon from "../../assets/combatants/fighter/doa/lisa-icon.png";
import LisaWin from "../../assets/combatants/fighter/doa/lisa-win.png";
import LisaLose from "../../assets/combatants/fighter/doa/lisa-lose.png";

import MarieRose from "../../assets/combatants/fighter/doa/marie-rose.png";
import MarieRoseIcon from "../../assets/combatants/fighter/doa/marie-rose-icon.png";
import MarieRoseWin from "../../assets/combatants/fighter/doa/marie-rose-win.png";
import MarieRoseLose from "../../assets/combatants/fighter/doa/marie-rose-lose.png";

export const seriesId = "Dead or Alive";

export default {
  christie: {
    seriesId,
    id: "christie",
    name: "Christie",
    description: "Christie is a cold-blooded woman with a sadistic streak, hired to kill Helena Douglas, but ended up killing her mother.",
    img: Christie,
    icon: ChristieIcon,
    opWinImg: ChristieWin,
    opLoseImg: ChristieLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "grey",
    hairColor: "white",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText: "Christie's silky tongue pleasures xxxx's tits.",
    roughPlayLvl: 22,
    seductionResistance: 68,
    grapplingProwess: 72,
    tongueProwess: 68,
    touchProwess: 25,
    cockProwess: 55,
    vaginaProwess: 20,
    anusProwess: 35,
    touchResistance: 50,
    breastResistance: 25,
    mouthResistance: 62,
    cockResistance: 35,
    vaginaResistance: 25,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  helena: {
    seriesId,
    id: "helena",
    name: "Helena",
    description: "Helena Douglas is an opera singer, pigua quan martial artist, & currently the leader of DOATEC.",
    img: Helena,
    icon: HelenaIcon,
    opWinImg: HelenaWin,
    opLoseImg: HelenaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "human",
    bodyShape: "busty",
    eyeColor: "blue-green",
    hairColor: "blonde",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 4,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "touch",
    prefTarget: "cock",
    uniqueAttackText: "Helena's soft gentle hands stroke & pleasure xxxx's ready cock.",
    roughPlayLvl: 10,
    seductionResistance: 85,
    grapplingProwess: 78,
    tongueProwess: 45,
    touchProwess: 80,
    cockProwess: 55,
    vaginaProwess: 45,
    anusProwess: 30,
    touchResistance: 75,
    breastResistance: 35,
    mouthResistance: 45,
    cockResistance: 55,
    vaginaResistance: 25,
    anusResistance: 40,
    orgasmLimit: 3,
  },
  hitomi: {
    seriesId,
    id: "hitomi",
    name: "Hitomi",
    description: "Hitomi was born to a German father and a Japanese mother, a black belt in karate by 18, under her fathers tutelage.",
    img: Hitomi,
    icon: HitomiIcon,
    opWinImg: HitomiWin,
    opLoseImg: HitomiLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "bluish-grey",
    hairColor: "dark brown",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText: "Hitomi runs her soft pussy lips over xxxx's tongue.",
    roughPlayLvl: -10,
    seductionResistance: 75,
    grapplingProwess: 78,
    tongueProwess: 45,
    touchProwess: 30,
    cockProwess: 55,
    vaginaProwess: 75,
    anusProwess: 35,
    touchResistance: 30,
    breastResistance: 65,
    mouthResistance: 50,
    cockResistance: 25,
    vaginaResistance: 75,
    anusResistance: 35,
    orgasmLimit: 3,
  },
  lisa: {
    seriesId,
    id: "lisa",
    name: "Lisa",
    description: "Lisa is a former scientist for DOATEC, she has a mysterious, shadowed past.",
    img: Lisa,
    icon: LisaIcon,
    opWinImg: LisaWin,
    opLoseImg: LisaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "dark pruple",
    hairColor: "raven",
    skinColor: "dark",
    height: 3,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText: "Lisa rubs her hard nipples against xxxx's rubbing her large rack against her breasts.",
    roughPlayLvl: 10,
    seductionResistance: 70,
    grapplingProwess: 72,
    tongueProwess: 35,
    touchProwess: 80,
    cockProwess: 35,
    vaginaProwess: 40,
    anusProwess: 20,
    touchResistance: 65,
    breastResistance: 35,
    mouthResistance: 65,
    cockResistance: 35,
    vaginaResistance: 35,
    anusResistance: 20,
    orgasmLimit: 3,
  },
  marieRose: {
    seriesId,
    id: "marieRose",
    name: "MarieRose",
    description: "Marie Rose is the cheerful, childish servant of Helena Douglas.",
    img: MarieRose,
    icon: MarieRoseIcon,
    opWinImg: MarieRoseWin,
    opLoseImg: MarieRoseLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "dark blue",
    hairColor: "blonde",
    skinColor: "pale",
    height: 1,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText: "marie licks xxxx's body all over, giving them soft seductive kisses.",
    roughPlayLvl: 5,
    seductionResistance: 65,
    grapplingProwess: 75,
    tongueProwess: 75,
    touchProwess: 40,
    cockProwess: 35,
    vaginaProwess: 45,
    anusProwess: 25,
    touchResistance: 75,
    breastResistance: 55,
    mouthResistance: 60,
    cockResistance: 45,
    vaginaResistance: 25,
    anusResistance: 25,
    orgasmLimit: 3,
  },
};
