import { PenisSize, BreastSize, holeSize } from "../../constants";

export default [
  {
    displayText: "Enjoy a professional blowjob",
    textOptions: [
      (p, c) => `Hmmm... ${p.name} wondered, what should I do with you today? ${
        p.pn.Cshe
      } smiled.
    
      "I want you to pleasure my cock"
      
      ${c.name} looked up at ${
        p.name
      }, bit her lip and nodded. She turned to face the hefty meat pole poking her face and gave it an lewd look.
      
      "Damn you have a pretty mouth, it just screams fuck me!" ${p.name} said.
      
      ${
        c.name
      } took a few deep breaths and began to lather the warm, powerful member with her tongue, then placing the glans in her mouth and began to suck it tenderly, taking to the back of her mouth.
      
      Slwoly pushing the ${PenisSize[p.penisSize]} member down triggered ${
        c.name
      }'s gag reflex, she let spit spray from her lips, as hungrily took the cock depper into her mouth. Little by little she swallowed the ${
        PenisSize[p.penisSize]
      } cock, her eyes looked up adoring at ${p.name} as she pushed further. ${
        p.name
      } appreciated ${
        c.name
      }'s lewd, reddening face, taking great pleasure in the girls soft mouth.
      
      ${
        p.name
      }'s sexual arousal grew, "God your mouth is so nice, suck me more, earn my hot salty spunk."
      
      ${
        c.name
      } suck harder bobbing her head and teasing along the cock's length with her tongue, enjoying the pulsing meat rod invading her mouth.
      
      Eventually her nose touched ${p.name}'s chest, ${
        c.name
      } smiled and released the cock spat out a big ball of spit and pre-cum onto the hard and ready rod.
      
      ${
        p.name
      } moaned, "I'd love to spray a fat, white load all over you, just paint you in white, or let you drain my balls with that adorable mouth, ooooooh, such hard choices, but first I need you to suck it some more."
      
      ${c.name} shut her eyes and took ${
        p.name
      }'s cock into her throat again', letting ${
        p.name
      } take control and eagerly fuck her face, ${
        c.name
      } looked up into her eyes as she enjoyed the feeling of the cock massaging her tongue and mouth. ${
        p.name
      } moaned loadly as ${p.pn.she} slowly reach orgasm.`,
    ],
  },
  {
    displayText: "Pleasure yourself with her soft breasts",
    textOptions: [
      (p, c) => `${p.name} bit ${
        p.pn.her
      } lip at the thin vest wrapped tightly around the ${
        p.name
      }’s supple and ${BreastSize[c.breastSize]} rack. ${p.pn.Cshe} ran ${
        p.pn.her
      } hand through ${c.name}’s soft ${
        c.hairColor
      } hair, and admired the soft skin of ${p.pn.her} neck.
    
    "You know ${
      c.name
    }, you have the most gorgeus breasts, I can't wait to fuck them." ${
        p.name
      } said lustily, as she looked into the girl's blushing eyes.
  
    ${p.names}'s hand fished out ${p.pn.her} ${
        PenisSize[p.penisSize]
      }, growing dick, pulling down ${p.pn.her} own trousers as ${
        p.pn.she
      } did. Gently ${p.pn.she} rubbed ${p.pn.her} growing phallus against ${
        c.name
      }’s delicate neck.
  
  ${p.pn.Cher} throbbing cock had grown sizeable, and ${
        p.name
      } now ran it along the soft fabric, pressing it against the centre, and slowly following the breast's soft curve.
  
  “God, your ${c.bodyShape} body is a delight.”
  
  ${
    p.pn.Cher
  } hands moved down, groping the tight fabric, and tenderly groping ${
        c.name
      }'s breast. The hand moved, and began kneading the girl's other tender breast, ${
        p.pn.her
      } fingers visible under the stretched fabric. ${
        c.name
      } writhed in pleasure under ${p.pn.her} gentle touch. ${
        p.name
      } pulled free and went to the table, and got a bottle of oil, returning, ${
        p.pn.she
      } poured the oil onto the girl's chest, biting ${
        p.pn.her
      } lip as the thick liquid coated her chest, giving it a alluring glow. ${
        p.name
      } made sure it was well smeared over the girls breasts, spreading with a seductive, soft stroke. ${
        p.pn.Cshe
      } quickly stripped fully naked, then, pulling ${c.name}'s head to ${
        p.pn.her
      } chest, plunged ${p.pn.her} ${
        PenisSize[p.penisSize]
      }, fully grown cock, into the girl’s bra. The oil lubed ${
        p.pn.her
      } entry nicely and ${p.pn.she} was able to slide ${
        p.pn.her
      } her hard rod between the two ${
        BreastSize[c.breastSize]
      }, soft breasts. ${p.pn.Cher} hips began to move faster, and ${
        p.pn.she
      } could hear quiet moans from as her bust was fucked by ${
        p.name
      }'s pulsing cock.
  
  “Mmmmmh, ${c.name}, your soft, ${p.pn.her} ${
        c.skinColor
      } breasts are heavenly, I could fuck your chest for hours!”, the girl whimpered in aroused delight.
  
  ${p.name} pounded ${c.name}’s tits quicker, stopping only to move ${
        p.pn.her
      } oily pre-cum dripping dick around the girl's vest, both of them groaning as the girl’s nipples brushed ${
        p.pn.her
      } throbbing cock. ${p.pn.Cshe} pushed deeper, ${
        p.pn.her
      } tip breaking out under the vest as  ${
        p.pn.she
      } carried on pounding her smooth, slippery rack. After a while of energetic fucking, ${
        p.pn.she
      } slowed, holding ${p.pn.her} sweaty, musky cock under ${
        c.name
      }’s nose, and smiled as she smelled it, and gave a few soft kisses.
  
  “Smell that ${
    c.name
  }, my sweaty cock meat is hungry for you, it wants to rub against you more, and after it’s had it's fill, my balls have a salty present ready for you!” ${
        c.name
      } smiled up at her and licked her lips, gently nodding.
  
  ${p.name} pushed ${c.name} onto her back, and pulled up her top, ${
        p.pn.she
      } rubbed ${p.pn.her} own bare chest against the girls’s, slathering ${
        p.pn.self
      }self in oil and teasing ${
        p.pn.her
      } nipples against the quivering young girl’s, while ${
        p.pn.her
      } hard cock pressed against ${c.name}’s toned abs.
  
  “God you little semen demon, your gonna drain my balls with that arousing body of yours!”
  
  Sitting on the girl, ${p.name} pressed ${c.name}’s tits tightly over ${
        p.pn.her
      } swollen cock, and continued tit-fucking her. Even with the generous helping of oil ${
        p.pn.she
      } she still added more as ${p.pn.she} pounded the girl into the floor.
  
  “I’m gonna cum soon, my balls are ready to release!”.`,
    ],
  },
  {
    displayText: "Fuck her hungry arse",
    textOptions: [
      (p, c) => `${p.name} smiled as ${p.pn.she} enjoyed the soft shapes of ${
        c.name
      }'s beautiful body.

    "Today I want your arse, I'm gonna make you a quivering wreck!", ${
      p.name
    } explained huskily, ${
        c.name
      }'s face went red, and she nodded, her eyes looking hungrily at the cock swinging between ${
        p.pn.her
      } legs, she leant forward and presented her behind.
    
    "What a soft peach bum, I can't to bury myself inside you!" ${
      p.name
    } offered with a deep voice laden with lust.
    
    ${p.name} took a deep breaths and began to slide ${
        p.pn.her
      } powerful member between ${
        c.name
      }s soft buttcheeks, fucking her arsecheeks with a lustful vigour. ${
        p.pn.Cshe
      } speed increased as ${p.pn.she} enjoyed their soft skin against  ${
        p.pn.her
      } cock.
    
    Then ${p.pn.she} slowed, and pulled free, and pressing  ${
        p.pn.her
      } glans against her anal ring.
      
      "Are you ready for the ride?" ${p.name} asked.
    
      ${
        c.name
      } nodded hurridly and pushed herself against the cock, slipping her back passage over ${
        p.pn.her
      } tip. ${p.name} hand groped one of the girls buttcheeks and stopped her.
    
      "Are you sure, do you think you deserve it?" ${
        p.name
      } asked again teasingly.
    
      A soft "please, please, fuck my arse" was tremblingly asked by ${c.name}.
    
      "Ok"
    
      ${p.name} pushed the ${
        PenisSize[p.penisSize]
      } member down smoothly into the greedy dark hole ,${
        c.name
      } cried out in pleasure, and ${p.name} swiftly ${
        p.pn.she
      } began fucking her abused behind, leaning forward to push her weight in. ${
        c.name
      }'s ${holeSize[c.anusSize]} arse held ${p.name} cock tightly as ${
        p.pn.she
      } continued ${p.pn.her} anal invasion.
      
      Little by little her arse swallowed the ${
        PenisSize[p.penisSize]
      } cock, her eyes wincing as ${p.name} pushed further in. ${
        p.name
      } leant forward more, and gently cupping the girl's chin pulled her back, arching her soft, supple back. ${
        p.pn.Cshe
      } kissed ${c.name}'s forehead, and pulling her back more, pushed ${
        p.pn.her
      } tongue into the girl's mouth, french kissing her as ${
        p.pn.she
      } continued plundering deep into her tender arse. Then she pulled back and looking into the girl's soft impassioned eyes.
    
      "Do you like it when I fuck you cute little arse?"
      "I love it" ${
        c.name
      } responed eagerly, her breath heaving with each pump from ${
        p.name
      }'s cock.
    
    "Deeper, I need to go deeper, but soon you'll be rewarded with my fresh creamy semen!"
    
    ${
      p.pn.Cshe
    } let the girl fall forward, leaving her arch and shift as waves of orgasmic pleasure were fired down her body. ${
        p.name
      }, fucked harder and deeper, ${p.pn.her} animal arousal clouding ${
        p.pn.her
      } mind, ${
        p.pn.her
      } only focus, fucking the tender, ravenous behind of this beautiful creature.
    
    "Arrrgh" ${
      p.name
    } screaming in orgamic delight, before pulling her hard cock free.
    
    "My balls are ready, I hope your ready for the giant wave of spunk you've made by balls produce!"`,
    ],
  },
  {
    displayText: "Lovingly fuck her wet pussy.",
    textOptions: [
      (p, c) => `${p.name} smiled as ${p.pn.she} enjoyed the soft shapes of ${
        c.name
      }'s beautiful body.

    "Today I want your pussy, I'm gonna leave you a quivering wreck!", ${
      p.name
    } explained huskily, ${
        c.name
      }'s face went red, and she nodded, her eyes looking hungrily at the cock swinging between ${
        p.pn.her
      } legs, she leant backward and presented her slowly moistening slit.
    
    "What a lovely lips, I'm going bury myself inside you!" ${
      p.name
    } ordered with a deep voice laden with lust.
    
    ${p.name} walked up to her, knelt and began to slide ${
        p.pn.her
      } growing, powerful member between ${
        c.name
      }s soft labia lips, fucking with a seductive gentle rythm. ${
        p.pn.Cher
      } speed increased as ${p.pn.she} enjoyed their soft skin against  ${
        p.pn.her
      } pulsing cock.
    
    Then ${p.pn.she} slowed, and moved down, and pressing  ${
        p.pn.her
      } tip against her tip.
      
      "Are you ready for me to fuck you into a shivering lump?" ${p.name} asked.
    
      ${
        c.name
      } nodded hurridly and pushed herself against the cock, slipping her lips over ${
        p.pn.her
      } tip. ${p.name} hand groped one of the girls breasts and stopped her.
    
      "Are you sure, do you think you deserve it?" ${
        p.name
      } asked again teasingly.
    
      A soft "please, please, fuck me, fuck me like you own me" was tremblingly asked by ${
        c.name
      }.
    
      "But I do"
    
      ${p.name} pushed the ${
        PenisSize[p.penisSize]
      } member down smoothly into her soft, damp pussy,${
        c.name
      } cried out in pleasure, and ${p.name} swiftly ${
        p.pn.she
      } began fucking her faster and harder, leaning forward to push her weight in. ${
        c.name
      }'s ${holeSize[c.anusSize]} pussy held ${p.name} cock tightly as ${
        p.pn.she
      } continued ${p.pn.her} pounding her lady cavern.
      
      Little by little her pussy swallowed the ${
        PenisSize[p.penisSize]
      } cock, her eyes wincing as ${p.name} pushed further in. ${
        p.name
      } leant forward more, and gently cupping the girl's head pulled her forward, embracing her in a deep french kiss, as ${
        p.pn.she
      } continued plundering deep into her tender pussy. Then she pulled back and looking into the girl's soft impassioned eyes.
    
      "Do you like it when I fuck you cute little pussy?"
      "I love it" ${
        c.name
      } responed eagerly, her breath heaving with each pump from ${
        p.name
      }'s cock.
    
    "Deeper, I need to go deeper, but soon you'll be rewarded with my fresh creamy semen!"
    
    ${
      p.pn.Cshe
    } let the girl lay on her back, and watched with lustful hunger as she arched and shifted as waves of orgasmic pleasure were fired down her body. ${
        p.name
      }, fucked harder and deeper, ${p.pn.her} animal arousal clouding ${
        p.pn.her
      } mind, ${
        p.pn.her
      } only focus, fucking the tender, ravenous womb of this beautiful creature.
    
    "Arrrgh" ${
      p.name
    } screaming in orgamic delight, before pulling her hard cock free.
    
    "My balls are ready, I hope your ready for the giant wave of spunk you've made by balls produce!"`,
    ],
  },
  {
    displayText: "nightime fucking",
    textOptions: [
      (p, c) =>
        `${p.name} walked up to ${c.name}'s bed, ${c.name} smiled enticingly, and motioned ${p.pn.her} over, ${p.name} undressed slowly, enjoying the sound of ${c.name} breathing lustily as everything was revealed.

      Naked, and with a throbbing member, ${p.name} walked over, ${c.name} hungrily grasped ${p.pn.her} cock and began to lather it with attention, caressing it with her hands and tongue, she switched to swallowing it deeply and teasing it with her teeth.
     
     ${p.name} pushed her down, and clambered on top, ${p.pn.she} first teased ${c.name}'s soft cunt, running ${p.pn.her} fingers and cock over it, stopping, and starting again, driving the girl wild with desire. Slowly ${p.pn.she} began to press it in, parting ${c.name}'s lowers lips and gently climbing into her moist passage, enjoying the soft cling. Spearing ${c.name}, ${p.pn.she} moved in for a forceful explorative kiss, gripping ${c.name}'s hair commandingly as ${p.pn.she} began to buck with ${p.pn.her} hips, ${c.name} struggled to continue the soft embrace of tongues as moans escaped from her orgasmic fucking.
     
     ${p.name} began to explore the girls neck, biting and licking as ${p.pn.her} thrusts became longer and deeper, ${p.pn.her} immense length was threaded all the way in and out, pulling loud cries from ${c.name}. Pulling and pinching at the girls supple body, and teasing her hard nipples, ${p.name} began to moan as ${p.pn.she} enjoyed the delights of ${c.name}'s sweet pussy.
     
     Feeling ${p.pn.self}self peak, ${p.pn.she} pulled the girl in close and pushed herself completly in, ${c.name} gripped her back.
     
       "Pump your seed deep into me ${p.name}, I want your hot cream to drown my womb."
       "Take it cumslut, drain my cock with your pussy!"
     
     ${p.name} gritted ${p.pn.her} teeth as multiples waves of cum burst into ${c.name}, ${p.pn.she} held her tight, squeezing out every drop right into the deepest part of her womb. Fully satiated, ${p.pn.she} again explored the girls reponsive mouth, then stood up, admiring ${c.name} lying on the bed breathing heavily and smiling at the warm feeling inside her.
     
     "Got a last few pumps of my hot cum for you left, now where shall I spray it?"

     ${c.name} bit her lip in lustful hunger.
     `,
    ],
  },
  {
    displayText: "Pounding at the tennis courts.",
    textOptions: [
      (p, c) =>
        `${p.name} smiled hungrily at ${c.name} the gorgeous ${c.bodyShape} ${
          c.hairColor
        }, admiring her ${
          BreastSize[c.breastSize]
        } bust, held tightly in a pink sportsbra, walking up behind her ${
          p.pn.she
        } gripped her soft tush.

        "Hi ${c.name} my sweet, could you get on your knees for me?"
      
      ${c.name} looked round at ${p.pn.her} and happily obliged, ${
          p.name
        } walked to her front, and presented ${p.pn.her} limp cock, ${
          c.name
        } quickly began to give it attention, rubbing it and licking it, she smiled up at ${
          p.name
        } as it grew hard.
      
        "It's looks lovely, I want it in me!"
      
      Her eyes looked up with powerful lust, ${p.name} stroked her soft hair.
      
        "My pleasure."
      
      ${p.name} proceeded to thread ${
          p.pn.her
        } cock into the girls tightly held cleavage, and began to fuck her chest, ${
          c.name
        } licked the ${
          PenisSize[p.penisSize]
        } cock's tip enthusiastically. After a short while of titfucking, ${
          p.name
        } dripped a large precum load into ${c.name}'s sports bra. ${
          c.name
        } began to moan before ${
          p.name
        } pulled her to her feet, and then just as quick freed her of her bottoms, lifting her legs as ${
          p.pn.she
        } took them, ${p.pn.she} looked at ${c.name}, who was still complaining.
      
        "I can still feel your precum squishing about, it's weird.."
        "enjoy it, you pulled it from my with those soft tits of yours!"
      
      ${
        p.name
      } placed the girl's bottoms over her head, blindfolding her eyes, and guided over to the net. Pulling a square in the net open, ${
          p.pn.she
        } pushed ${
          c.name
        }'s head through, then pushed her hands through other holes, ducking under ${
          p.pn.she
        } tied the girls hands to the net, as ${c.name} purred demurely.

      "what are you planning to do to your poor slave?"
      "Oh, she'll find out soon enough."
      
      ${
        p.name
      } then returned to the girls bottom, and grabbing it firmly in both hands ${
          p.pn.she
        } explored her cunt with ${p.pn.her} hungry tongue, ${
          p.pn.she
        } could feel ${c.name} buck and shift under the waves of pleasure.
      
      Fully wet, ${p.name} stood and gently slid ${p.pn.her} cock in, ${
          c.name
        }'s pussy was tight, but ${p.name} pushed on, beginning slowly, ${
          p.pn.her
        } hips sped up as ${p.pn.she} entered further. ${
          c.name
        } writhed and moaned meekly as ${
          p.name
        } began to pound her roughly, fucking her tight pussy with a growing lustful energy that made ${
          c.name
        }'s legs shudder.
      
      ${p.name} moaned as ${
          p.pn.she
        } continued to fuck the girl's wet clinging cunt, enjoying the delights of her soft pussy's grip and squirming body. 
      
      "Please cum in me!"
      "Maybe sweety, you have to earn my warm cum!"
       `,
    ],
  },
];
