import Ahri from "../../assets/combatants/league-of-legends/ahri.png";
import AhriIcon from "../../assets/combatants/league-of-legends/ahri-icon.png";
import AhriWin from "../../assets/combatants/league-of-legends/ahri-win.png";
import AhriLose from "../../assets/combatants/league-of-legends/ahri-lose.png";

import Akali from "../../assets/combatants/league-of-legends/akali.png";
import AkaliIcon from "../../assets/combatants/league-of-legends/akali-icon.png";
import AkaliWin from "../../assets/combatants/league-of-legends/akali-win.png";
import AkaliLose from "../../assets/combatants/league-of-legends/akali-lose.png";

import Jinx from "../../assets/combatants/league-of-legends/jinx.png";
import JinxIcon from "../../assets/combatants/league-of-legends/jinx-icon.png";
import JinxWin from "../../assets/combatants/league-of-legends/jinx-win.png";
import JinxLose from "../../assets/combatants/league-of-legends/jinx-lose.png";

import Katarina from "../../assets/combatants/league-of-legends/katarina.png";
import KatarinaIcon from "../../assets/combatants/league-of-legends/katarina-icon.png";
import KatarinaWin from "../../assets/combatants/league-of-legends/katarina-win.png";
import KatarinaLose from "../../assets/combatants/league-of-legends/katarina-lose.png";

import Luxanna from "../../assets/combatants/league-of-legends/luxanna.png";
import LuxannaIcon from "../../assets/combatants/league-of-legends/luxanna-icon.png";
import LuxannaWin from "../../assets/combatants/league-of-legends/luxanna-win.png";
import LuxannaLose from "../../assets/combatants/league-of-legends/luxanna-lose.png";

import Nidalee from "../../assets/combatants/league-of-legends/nidalee.png";
import NidaleeIcon from "../../assets/combatants/league-of-legends/nidalee-icon.png";
import NidaleeWin from "../../assets/combatants/league-of-legends/nidalee-win.png";
import NidaleeLose from "../../assets/combatants/league-of-legends/nidalee-lose.png";

import Riven from "../../assets/combatants/league-of-legends/riven.png";
import RivenIcon from "../../assets/combatants/league-of-legends/riven-icon.png";
import RivenWin from "../../assets/combatants/league-of-legends/riven-win.png";
import RivenLose from "../../assets/combatants/league-of-legends/riven-lose.png";

import Sarah from "../../assets/combatants/league-of-legends/sarah.png";
import SarahIcon from "../../assets/combatants/league-of-legends/sarah-icon.png";
import SarahWin from "../../assets/combatants/league-of-legends/sarah-win.png";
import SarahLose from "../../assets/combatants/league-of-legends/sarah-lose.png";

import SonaBuvelle from "../../assets/combatants/league-of-legends/sona-buvelle.png";
import SonaBuvelleIcon from "../../assets/combatants/league-of-legends/sona-buvelle-icon.png";
import SonaBuvelleWin from "../../assets/combatants/league-of-legends/sona-buvelle-win.png";
import SonaBuvelleLose from "../../assets/combatants/league-of-legends/sona-buvelle-lose.png";

export const seriesId = "League of Legends";

export default {
  ahri: {
    seriesId,
    id: "ahri",
    name: "Ahri",
    description:
      "Innately connected to the latent power of Runeterra, Ahri is a vastaya who can reshape magic into Orb of Deception orbs of raw energy.",
    img: Ahri,
    icon: AhriIcon,
    opWinImg: AhriWin,
    opLoseImg: AhriLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "buxom",
    eyeColor: "gold",
    hairColor: "black",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 3,
    vaginaSize: 2,
    anusSize: 1,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "mouth",
    uniqueAttackText:
      "Ahri opens her pussy and presses it soft wetness against xxxx's mouth.",
    roughPlayLvl: 10,
    seductionResistance: 55,
    grapplingProwess: 48,
    tongueProwess: 34,
    touchProwess: 48,
    cockProwess: 25,
    vaginaProwess: 50,
    anusProwess: 35,
    touchResistance: 28,
    breastResistance: 40,
    mouthResistance: 34,
    cockResistance: 30,
    vaginaResistance: 52,
    anusResistance: 36,
    orgasmLimit: 3,
  },
  akali: {
    seriesId,
    id: "akali",
    name: "Akali",
    description:
      "Abandoning the Kinkou Order and her title of the Fist of Shadow, Akali OriginalSquare Akali now strikes alone, ready to be the deadly weapon her people need.",
    img: Akali,
    icon: AkaliIcon,
    opWinImg: AkaliWin,
    opLoseImg: AkaliLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "toned",
    eyeColor: "red",
    hairColor: "black",
    skinColor: "pale",
    height: 3,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "touch",
    uniqueAttackText:
      "Akali runs hers slobbering tongue over xxxx's back, then moves to sucking their neck.",
    roughPlayLvl: 25,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 45,
    touchProwess: 35,
    cockProwess: 40,
    vaginaProwess: 30,
    anusProwess: 35,
    touchResistance: 25,
    breastResistance: 30,
    mouthResistance: 44,
    cockResistance: 30,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  jinx: {
    seriesId,
    id: "jinx",
    name: "Jinx",
    description:
      "A manic and impulsive criminal from Zaun, Jinx lives to wreak havoc without care for the consequences.",
    img: Jinx,
    icon: JinxIcon,
    opWinImg: JinxWin,
    opLoseImg: JinxLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "petite",
    eyeColor: "pink",
    hairColor: "blue",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 1,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 1,
    prefAttack: "touch",
    prefTarget: "touch",
    uniqueAttackText: "Jinx rubs her nubile young body against xxxx's body.",
    roughPlayLvl: 5,
    seductionResistance: 55,
    grapplingProwess: 60,
    tongueProwess: 35,
    touchProwess: 58,
    cockProwess: 35,
    vaginaProwess: 45,
    anusProwess: 45,
    touchResistance: 60,
    breastResistance: 32,
    mouthResistance: 35,
    cockResistance: 38,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  nidalee: {
    seriesId,
    id: "nidalee",
    name: "Nidalee",
    description:
      "Raised in the deepest jungle, Nidalee is a master tracker who can shapeshift into a ferocious cougar at will.",
    img: Nidalee,
    icon: NidaleeIcon,
    opWinImg: NidaleeWin,
    opLoseImg: NidaleeLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "green",
    hairColor: "black",
    skinColor: "bronze",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 3,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "vagina",
    prefTarget: "cock",
    uniqueAttackText:
      "Nidalee roughly plants herself onto xxxx's cock, and begins bucking while grunt wildly.",
    roughPlayLvl: 15,
    seductionResistance: 50,
    grapplingProwess: 60,
    tongueProwess: 45,
    touchProwess: 25,
    cockProwess: 35,
    vaginaProwess: 55,
    anusProwess: 35,
    touchResistance: 32,
    breastResistance: 40,
    mouthResistance: 32,
    cockResistance: 45,
    vaginaResistance: 45,
    anusResistance: 38,
    orgasmLimit: 3,
  },
  katarina: {
    seriesId,
    id: "katarina",
    name: "Katarina",
    description:
      "Decisive in judgment and lethal in combat, Katarina is a Noxian assassin of the highest caliber.",
    img: Katarina,
    icon: KatarinaIcon,
    opWinImg: KatarinaWin,
    opLoseImg: KatarinaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "human",
    bodyShape: "hourglass",
    eyeColor: "green",
    hairColor: "red",
    skinColor: "fair",
    height: 2,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "breasts",
    uniqueAttackText: "Katarina gropes and pulls on xxxx's nipples, whilst kissing their neck.",
    roughPlayLvl: -5,
    seductionResistance: 62,
    grapplingProwess: 65,
    tongueProwess: 35,
    touchProwess: 62,
    cockProwess: 45,
    vaginaProwess: 45,
    anusProwess: 30,
    touchResistance: 65,
    breastResistance: 45,
    mouthResistance: 40,
    cockResistance: 55,
    vaginaResistance: 35,
    anusResistance: 50,
    orgasmLimit: 3,
  },
  luxanna: {
    seriesId,
    id: "luxanna",
    name: "Luxanna",
    description:
      "Luxanna Crownguard hails from Demacia, an insular realm where magical abilities are viewed with fear and suspicion.",
    img: Luxanna,
    icon: LuxannaIcon,
    opWinImg: LuxannaWin,
    opLoseImg: LuxannaLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "curvaceous",
    eyeColor: "blue",
    hairColor: "blonde",
    skinColor: "pale",
    height: 2,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 1,
    anusSize: 2,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "cock",
    uniqueAttackText:
      "Luxanna licks all around xxxx's hard shaft, then gently suckles the glans.",
    roughPlayLvl: 12,
    seductionResistance: 55,
    grapplingProwess: 48,
    tongueProwess: 48,
    touchProwess: 30,
    cockProwess: 25,
    vaginaProwess: 35,
    anusProwess: 25,
    touchResistance: 35,
    breastResistance: 55,
    mouthResistance: 50,
    cockResistance: 35,
    vaginaResistance: 30,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  riven: {
    seriesId,
    id: "riven",
    name: "Riven",
    description:
      "Once a swordmaster in the warhosts of Noxus, Riven is an expatriate in a land she previously tried to conquer.",
    img: Riven,
    icon: RivenIcon,
    opWinImg: RivenWin,
    opLoseImg: RivenLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "muscular",
    eyeColor: "gold",
    hairColor: "white",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 2,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 3,
    prefAttack: "tongue",
    prefTarget: "anus",
    uniqueAttackText: "Riven digs her tongue deep into xxxx's anus.",
    roughPlayLvl: 15,
    seductionResistance: 62,
    grapplingProwess: 65,
    tongueProwess: 62,
    touchProwess: 40,
    cockProwess: 35,
    vaginaProwess: 32,
    anusProwess: 35,
    touchResistance: 42,
    breastResistance: 50,
    mouthResistance: 60,
    cockResistance: 35,
    vaginaResistance: 35,
    anusResistance: 45,
    orgasmLimit: 3,
  },
  sarah: {
    seriesId,
    id: "sarah",
    name: "Sarah Fortune",
    description:
      "A Bilgewater captain famed for her looks but feared for her ruthlessness, Sarah Fortune paints a stark figure among the hardened criminals of the port city.",
    img: Sarah,
    icon: SarahIcon,
    opWinImg: SarahWin,
    opLoseImg: SarahLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "red",
    skinColor: "fair",
    height: 3,
    penisSize: 0,
    breastSize: 5,
    vaginaSize: 2,
    anusSize: 3,
    throatSize: 2,
    prefAttack: "touch",
    prefTarget: "vagina",
    uniqueAttackText:
      "Sarah's long, slender fingers dance deep inside xxxx's vagina.",
    roughPlayLvl: 15,
    seductionResistance: 45,
    grapplingProwess: 55,
    tongueProwess: 35,
    touchProwess: 55,
    cockProwess: 25,
    vaginaProwess: 25,
    anusProwess: 45,
    touchResistance: 50,
    breastResistance: 45,
    mouthResistance: 40,
    cockResistance: 25,
    vaginaResistance: 45,
    anusResistance: 25,
    orgasmLimit: 3,
  },
  sonaBuvelle: {
    seriesId,
    id: "sonaBuvelle",
    name: "Sona Buvelle",
    description:
      "Sona is Demacia's foremost virtuoso of the stringed etwahl, speaking only through her graceful chords and vibrant arias.",
    img: SonaBuvelle,
    icon: SonaBuvelleIcon,
    opWinImg: SonaBuvelleWin,
    opLoseImg: SonaBuvelleLose,
    gender: "female",
    isWoman: true,
    hasCock: false,
    race: "Human",
    bodyShape: "busty",
    eyeColor: "blue",
    hairColor: "blue",
    skinColor: "pale",
    height: 3,
    penisSize: 0,
    breastSize: 4,
    vaginaSize: 2,
    anusSize: 2,
    throatSize: 2,
    prefAttack: "tongue",
    prefTarget: "breasts",
    uniqueAttackText:
      "Sona Buvelle's long lucious tongue softly teases xxxx's heaving busom with wet tongue.",
    roughPlayLvl: -10,
    seductionResistance: 58,
    grapplingProwess: 52,
    tongueProwess: 50,
    touchProwess: 22,
    cockProwess: 35,
    vaginaProwess: 25,
    anusProwess: 28,
    touchResistance: 45,
    breastResistance: 55,
    mouthResistance: 25,
    cockResistance: 28,
    vaginaResistance: 52,
    anusResistance: 38,
    orgasmLimit: 3,
  },
};
